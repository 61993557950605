select {
  border-radius: 0;
}

input[type=text],
input[type=email],
input[type=tel],
input[type=search] {
  width: 100%;
  text-align: left;
  padding: 1em 25px;
  outline: 0;
  outline-offset: 0;
  line-height: 1;
  border: none;
  background: $brand-primary;
  color: #fff;
}

.search-form {
  input[type=search] {
    float: left;
  }

  input[type=submit] {
    display: none;
  }

  ::placeholder {
    color: #fff;
  }

}
