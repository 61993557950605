/* Blog */

body.single-post,
body.page-template-content-page-our-50th-story {
  main {
    @include topPadding();
    color: $brand-primary;

    @include bp(mobile-tablet) {
      padding-top: 150px;
    }

    .entry-header {
      @include linkPrimaryColor();
      @include linkUnderLine();

      margin-bottom: 65px;

      a {
        margin-left: 10px;
      }
    }

    h1 {
      @include remy(48);
      line-height: percentage(54 / 48);
      width: percentage(12 / 20);
      margin-bottom: 0.5em;

      @include bp(mobile) {
        width: 100%;
        @include remy(37);
      }
    }

    .article-wrap {
      display: table;

      .entry-content,
      .entry-quote {
        display: inline-block;
        vertical-align: middle;
      }

      .entry-content {
        width: percentage(12 / 20);

        @include bp(mobile) {
          width: 100%;
        }

        p {
          color: $brand-tertiary;
          margin: 0 0 1em;

          @include linkUnderLine();
          @include linkPrimaryColor();

          &.hero-caption {
            color: $brand-primary;
            margin: 30px 0 4em;

            @include bp(mobile-tablet) {
              margin: 20px 0 4em;
            }
          }

          &.single-header-text+p {
            @include remy(20);
            padding-bottom: 10px;

            @include bp(mobile) {
              @include remy(18);
            }
          }
        }

        .single-header-text p {
          @include remy(20);
          padding-bottom: 10px;

            @include bp(mobile) {
              @include remy(18);
            }
        }

        h2 {
          @include remy(38);
          margin-bottom: 14px;
        }

        section.section-single-post {
          h3 {
            @include remy(26);
            // margin-bottom: 0;
          }

          img {
            margin: 3em 0;
            width: 100%;
          }

          margin-bottom: 45px;

          //@include bp(mobile) {
          //  margin-bottom: 0;
          //}
        }

        .entry-footer {
          display: table;
          width: 100%;
          margin-bottom: 105px;

          @include bp(mobile) {
            margin-bottom: 3em;
          }

          .entry-footer-tag,
          .entry-footer-share {
            display: inline-block;
          }

          .entry-footer-tag {
            width: percentage(8 / 12);
            margin-right: percentage(1 / 12);

            @include linkUnderLine();
            @include linkPrimaryColor();

            @include bp(mobile) {
              margin-right: 0;
              width: 100%;
              margin-bottom: 32px;
            }

            a {
              margin-left: 15px;
            }
          }

          .entry-footer-share {
            width: percentage(3 / 12);
            text-align: right;

            @include hoverFade();

            @include bp(mobile) {
              width: 100%;
              text-align: left;
            }


            a {
              margin-left: 10px;
            }
          }

        }

      }

      .entry-quote {
        width: percentage(5 / 20);
        margin-left: percentage(2 / 20);

        @include bp(mobile) {
          display: none;
        }

        .quote-text {
          @include remy(38);
          @include fontStyle2();
          line-height: percentage(40 / 38);
          margin-bottom: 40px;
        }
      }
    }

    .entry-navi {
      background-color: $brand-primary;
      padding: 42px 0 54px;

      @include bp(mobile-tablet) {
        padding: 42px 0;
      }

      color: $brand-secondary;
      @include linkSecondaryColor();

      .paging {
        position: relative;
        @include clearfix();
        positon: relative;

        .prev, .next {
          positon: relative;
          display: table;
          width: percentage(5 / 20);

          @include bp(mobile) {
            margin: 1em 0;
            width: 100%;
          }

          @include hoverFade();

          p, svg {
            display: inline-block;
            vertical-align: top;
          }

          span {
            display: block;
            @include remy(26);
            @include fontStyle2();
            line-height: percentage(30 / 26);
            margin-top: 0.4em;
          }

          svg {
            position: absolute;
            // margin-top: 2.8em;
          }
        }

        .prev {
          float: left;
          position: relative;

          svg {
            left: -20px;
            top: 3em;
            // margin-right: 0.5em;
          }

          @include bp(mobile) {
            text-align: right;
            margin-top: 2.5em;
            margin-bottom: 0;

            svg {
              top: 5px;
              left: auto;
              right: 120px;
            }
          }
        }

        .next {
          float: right;
          position: relative;

          svg {
            right: -20px;
            top: 3em;
            // margin-left: 0.5em;
            // margin-right: -1em;
          }

          @include bp(mobile) {
            border-bottom: solid 1px $brand-secondary;
            padding-bottom: 2em;
            width: 100%;
            margin-bottom: 0;

            svg {
              top: 5px;
              left: 95px;
              right: auto;
            }
          }
        }
      }

    }
  }
}

.single-post-wrapper img {
  width: 100%;
}
