/* Quote */

$nf-bg-color: #eff6e5;

body.get-a-quote {
  main {
    color: $brand-primary;
    a, a:visited {
      color: $brand-primary;
    }

    #page-intro-box {
      padding-bottom: 68px;

      @include bp(mobile-tablet) {
        padding-bottom: 50px;
      }

      #page-intro {
        h1 {
          width: 100%; //percentage(4/8);
          @include remy(80);
          line-height: percentage(70/80);
          margin-bottom: 30px;

          @include bp(mobile-tablet) {
            // width: percentage(14/20);
            @include remy(48);
          }
        }

        p {
          margin-bottom: 0;
        }
      }
    }
  }
}

.quote-container {
  width: percentage(13 / 20);

  @include bp(mobile-tablet) {
    width: 100%;
  }
}

.ninja-forms-form-wrap {
  //background: purple;
}

// Hide note on top of forms
.nf-before-form-content {
  display: none;
}

#nf-form-1-cont {

  border-radius: 0;

  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: $brand-secondary;
  }
  ::-moz-placeholder { /* Firefox 19+ */
    color: $brand-secondary;
  }
  :-ms-input-placeholder { /* IE 10+ */
    color: $brand-secondary;
  }
  :-moz-placeholder { /* Firefox 18- */
    color: $brand-secondary;
  }
}

#nf-field-3-container {
  padding-top: 30px;
}

// 1 First Name
// 5 Last Name
// 2 Email
// 6 Phone
// 7 City
// 8 Province/State
// 9 Company (optional)
// 10 Already~
// 19 Do you want to upload~

// 21 Any additional~

// 11 Product
// 12 Quantity
// 13 Page Count
// 14 Size
// 15 Paper Stock
// 17 Inks
// 18 Finishing / Bindery
// 24 Blank

// 4 Get a Quote
// 20 Required fields

#nf-field-1-container,
#nf-field-5-container,
#nf-field-2-container,
#nf-field-6-container,
#nf-field-7-container,
#nf-field-8-container {
  margin-bottom: 25px;
}

#nf-field-5-container,
#nf-field-6-container,
#nf-field-8-container,
#nf-field-9-container {
  @include bp(mobile-tablet) {
    width: 100%!important;
    margin-left: 0;
  }
}

#nf-field-9-container {
  margin-bottom: 60px;
}

#nf-field-8-container,
#nf-field-17-container,
#nf-field-18-container,
#nf-field-25-container,
#nf-field-26-container,
#nf-field-27-container,
#nf-field-28-container,
#nf-field-29-container,
#nf-field-50-container,
#nf-field-51-container,
.list-select-wrap,
.listcountry-wrap {
  select {
    background-image: url('../../assets/images/downarrowicon.svg');
    background-position: right 14px center;
    background-repeat: no-repeat;
  }
}

.ninja-forms-field {
  background-color: $brand-primary;
  color: $brand-secondary;
  padding: 16px 25px;
  border: none;
  @include fontStyle();

  @include bp(mobile-tablet) {
    padding: 16px 30px;
  }

  .list-select-wrap &,
  .listcountry-wrap & {
    padding: 14px 25px;

    @include bp(mobile-tablet) {
      padding: 14px 7.5%;
    }
  }

}

.one-half {
  width: percentage(6.5 / 20);

  @include bp(mobile-tablet) {
    width: 100%!important;
  }
}

#nf-field-10-container,
#nf-field-19-container {
  margin-bottom: 25px;

  @include bp(mobile-tablet) {
    margin: 0 30px 40px;
    width: percentage(20 / 22);
  }

  h2 {
    @include remy(26);
    line-height: percentage(30/26);
    margin-bottom: 20px;
  }

  p {
    margin: 0;

    @include linkUnderLine();
  }
}

#nf-field-10-container {
  clear: both;
  margin-top: 25px;
}

// Specification & Upload

#nf-field-10-container h2 {
  width: percentage(4 / 6.5);
}

#nf-field-19-container h2 {
  width: percentage(3 / 6.5);

  @include bp(mobile-tablet) {
    width: percentage(4 / 6.5)!important;
  }
}

// Text

#nf-field-21-container {
  background-color: $nf-bg-color;
  text-align: left;
  margin: 0;
  padding: 27px 20px 26px;
  @include fontStyle();

  @include bp(mobile-tablet) {
    padding: 27px 30px 26px;
  }
}

// Optional forms with background
#nf-field-11-container,
#nf-field-12-container,
#nf-field-13-container,
#nf-field-14-container,
#nf-field-15-container,
#nf-field-17-container,
#nf-field-24-container,
#nf-field-25-container,
#nf-field-26-container,
#nf-field-27-container,
#nf-field-28-container,
#nf-field-29-container,
#nf-field-30-container,
#nf-field-31-container,
#nf-field-48-container,
#nf-field-49-container,
#nf-field-50-container,
#nf-field-51-container,
.field-specs {
  background-color: $nf-bg-color;
  margin: 0;
  margin-bottom: 0 !important;
  padding-bottom: 25px;

  &.textbox-container {
    // padding-bottom: 21px;
    margin-bottom: 0;
  }
}

#nf-field-18-container {
  background-color: $nf-bg-color;
  margin: 0;
  padding-bottom: 20px;
  margin-bottom: 68px;

  @include bp(mobile-tablet) {
    padding-bottom: 0;
    margin-bottom: 0;
  }
}

#nf-field-24-container {
  padding-bottom: 52px;

  @include bp(mobile-tablet) {
    margin-bottom: 40px;
  }
}

#nf-field-12-container,
#nf-field-14-container,
#nf-field-17-container,
#nf-field-24-container,
#nf-field-26-container,
#nf-field-28-container,
#nf-field-30-container,
#nf-field-48-container,
#nf-field-50-container,
.field-specs-right {
  width: 51.2820513% !important;

  @include bp(mobile-tablet) {
    width: 100%!important;
  }
}

// R
#nf-field-12-container,
#nf-field-14-container,
#nf-field-24-container,
.field-specs-right {
  input {
    width: percentage(6 / 6.5);
    margin-left: percentage(0.2 / 6.5);

    @include bp(mobile-tablet) {
      margin-left: 0!important;
      width: 100%!important;
    }
  }
}

// R
#nf-field-17-container,
#nf-field-26-container,
#nf-field-28-container,
#nf-field-30-container,
#nf-field-48-container,
#nf-field-50-container,
.field-specs-right {
  .nf-field {
    width: percentage(6 / 6.5) !important;
    margin-left: percentage(0.2 / 6.5);

    @include bp(mobile-tablet) {
      margin-left: 0!important;
      width: 100%!important;
      padding: 0 30px;
    }
  }
}

// L
#nf-field-11-container,
#nf-field-13-container,
#nf-field-15-container,
.field-specs-left {
  input {
    margin-left: percentage(0.4 / 6.5);
    width: percentage(6 / 6.5);

    @include bp(mobile-tablet) {
      margin-left: 0!important;
      width: 100%!important;
    }
  }
}

#nf-field-18-container,
#nf-field-25-container,
#nf-field-27-container,
#nf-field-29-container,
#nf-field-31-container,
#nf-field-49-container,
#nf-field-51-container,
.field-specs-left  {
  .nf-field {
    margin-left: percentage(0.4 / 6.5);
    width: percentage(6 / 6.5);

    @include bp(mobile-tablet) {
      margin-left: 0!important;
      width: 100%!important;
      padding: 0 30px;
    }
  }
}

// blank
#nf-field-24-container {
  input {
    visibility: hidden;

    @include bp(mobile-tablet) {
      display: none;
    }
  }
}

// Hide until clicked
#nf-field-21-container,
#nf-field-11-container,
#nf-field-12-container,
#nf-field-13-container,
#nf-field-14-container,
#nf-field-15-container,
#nf-field-17-container,
#nf-field-18-container,
#nf-field-24-container,
#nf-field-25-container,
#nf-field-26-container,
#nf-field-27-container,
#nf-field-28-container,
#nf-field-29-container,
#nf-field-30-container,
#nf-field-31-container,
#nf-field-48-container,
#nf-field-49-container,
#nf-field-50-container,
#nf-field-51-container,
.field-specs {
  display: none;

  &.show {
    display: block;
  }
}

.field-specs-right {
  margin-left: 0 !important;
}

// Get a Quote button and note
#nf-field-4-container {
  width: percentage(6 / 13);

  input {
    @include remy(16);
    width: percentage(3 / 6);
    color: $brand-primary;
    background-color: $brand-secondary;
    border: solid 1px $brand-primary!important;
  }

  @include bp(mobile-tablet) {
    width: 100%!important;

    input {
      width: 100%;
    }
  }
}

#nf-field-20-container {
  margin-left:0;
  @include remy(14);
  width: percentage(7 / 13);
  text-align: right;
}

//Labels

.nf-field-label label {
  font-weight: 500 !important;
}

.label-above .nf-field-label {
    margin-bottom: 5px !important;

    @include bp(mobile-tablet) {
    width: 85% !important;
    margin: 0 auto 5px !important;
    display: block;
  }
}

.nf-error-msg, .ninja-forms-req-symbol {
    color: $brand-primary !important;
}

.nf-error .ninja-forms-field {
    border: 1px solid $brand-primary !important;
}

.quote-more-wrapper {
  padding-left: 20px;
}

a#quoteMore:before {
  content: url('../../assets/images/plusgreen.svg');
  position: absolute;
  left: -20px;
  top: -2px;
  padding-right: 5px;
}

a#quoteMore.toggled:before {
  content: url('../../assets/images/minusgreen.svg');
  top: -8px;
}
