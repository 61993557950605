/* common */

body {
  main {
    display: none;
    animation: alpha0to1 0.5s $ease-in-sine;
  }
}

.page-default-container {
  h1,
  p {
    color: $brand-primary;
  }
}

.bg-image {
  position: absolute;
  @include bgFitFull();

  @include bp(mobile-tablet) {
    @include bgFitFull(305px);
  }
}

#page-intro,
.page-intro {
  position: relative;
  //width: percentage(8 / $grid-20);
  width: 50%;

  @include bp(mobile) {
    width: 100%;
  }
  &:first-child(){
    @include topPadding();

    .is--cookie__notaccepted & {
      padding-top: 160px;
    }
  }
  //@include topPadding();

  @include bp(mobile) {
    padding-top: 150px; //percentage(250 / $content-mobile-max-width);
  }

  h1 {
    @include remy(72);
    line-height: percentage(78 / 76);
    margin-bottom: 22.5px;

    @include bp(mobile) {
      @include remy(48);
      //margin-bottom: percentage(35 / $content-mobile-max-width);
    }
  }

  p {
    @include remy(18);
    line-height: 28px;
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
    }

    @include bp(mobile) {
      @include remy(14)
      width: 100%;
      line-height: 20px;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 48px;
      }
    }

  }
}

#page-intro-box,
.page-intro-box {
  display: table;
  padding-bottom: 80px;
  @include clearfix();

  &:first-child(){
    @include topPadding();

    .is--cookie__notaccepted & {
      padding-top: 160px;
    }

  }



  @include bp(mobile) {
    padding-bottom: 0;
    padding-top: 150px;
  }

  #page-intro,
  .page-intro {
    float: left;
    vertical-align: top;
    padding-top: 0;

    p.submitBtn {
      width: percentage(2.5 / 6 );
      @include boxLinkWhite( 100% );
    }
  }

  // Portfolio
  #portfolio-menu-list {
    float: right;
    vertical-align: top;
    padding-top: 38px;
    //width: 320px;

    @include bp(mobile) {
      display: none;
    }

    li {
      @include fontStyle2();
      @include remy(26);
      padding-left: 15px;
      line-height: 32px;

      span {
        display: inline-block;
        @include fontStyle();
        @include remy(16);
        vertical-align: middle;
        width: 22px;
      }
      @include hoverMidLine(40%, 18px);
    }
  }

  // Submit,
  #question-on-right {
    clear: both;
    vertical-align: top;
    // margin-left: percentage(6 / $grid-20);
    padding-top: 38px;
    // width: percentage(5 / $grid-20 );

    p {
      margin-bottom: 26px;
    }

    p.mainText {
      @include fontStyle2();
      @include remy(26);
      line-height: 36/26;
      margin-bottom: 14px;
    }

    @include linkUnderLine();

    @include bp(mobile) {
      display: none;
    }
  }
}

.accordion-menu {
  width: percentage(13 / 20 );
  //margin-left: -13.428px;

  @include bp(mobile) {
    margin-left: 0;
    width: 100%;
  }

  dt {

    cursor:pointer;
    @include fontStyle2();
    @include remy(26);

    @include bp(mobile) {
      margin-left: 0;
      width: 100%;
      padding-left: 20px;
    }
  }

  dd {
    display:none;
  }
}

.mobile-dropdown-list {
  display: none;
  width: 100%;
  background-color: $brand-primary-transparent;
  color: $brand-secondary;
  @include linkSecondaryColor();
  margin-bottom: 38px;

  // @include bp(tablet) {
  //   display: block;
  // }

  @include bp(mobile) {
    display: block;
  }

  li {
    position: relative;
    @include fontStyle2();
    @include remy(23);
    padding-left: percentage(50 / 682);
    width: percentage(632 / 682);
    background-image: url('../../assets/images/down-chevron.svg');
    background-position: right 32px;
    background-size: 20px;
    background-repeat: no-repeat;
    cursor: pointer;

    span {
      @include fontStyle();
      @include remy(14);
      // @include fz_vw(28);
      vertical-align: middle;
    }

    a {
      display: inline-block;

      &.first {
        margin: 0.7em 0;

        // &:after {
        //   content: '';
        //   width: 100%;
        //   position: absolute;
        //   left: 0;
        //   bottom: 40%;
        //   border-width: 0 0 1px;
        //   border-style: solid;
        // }
      }
    }

    svg {
      display: none;
      @include abs(67px, 0, 0, 100%);
      transform: translate(-150%, -50%) rotate(90deg) ;
    }

    &.open {
      background-image: url('../../assets/images/up-chevron.svg');
      svg {
        transform: translate(-150%, -50%) rotate(270deg) ;
      }
    }

    ul {
      display: none;
      border-top: solid 1px $brand-secondary;
      padding: 1em 0;
      width: 100%;

      li {
        padding-left: 0;
        width: 100%;
        background-image: none;

        a {
          margin-bottom: 0.2em;

          @include bp(mobile-tablet) {
            margin-bottom: 2px;
          }
        }
      }
    }

    @include hoverMidLine();
    //@include hoverMidLine();

    // I don't want to use it, but need to override.
    a, a:visited {
      color: $brand-secondary!important;
    }

  }
}

ul.mobileDropDownMenu {
  display: none;

  @include bp(tablet) {
    display: block;
  }

  @include bp(mobile) {
    display: block;
  }

  margin: 0px;
  padding: 0px 0px 0px 15px;
  background-color: #cc0000;

  li {
    width: 125px;
    display: inline-block;
    list-style-type: none;
    position: relative;
  }

  a {
    background-color: #cc0000;
    color: white;
    line-height: 40px;
    text-align: center;
    text-decoration: none;
    font-weight: bold;
    display: block;

    &:hover {
      background-color: #ffdddd;
      color: #dd0000;
    }
  }

  li ul {
    display: none;
  }
}

/* Box Link List */

.boxLinkWhite {
  @include boxLinkWhite(100%);
}

/* Box Link List */

h2.boxLinkListTitle {
  @include remy(48);
  line-height: 1;
  margin: 0 0 47px;

  @include bp(mobile) {
    @include remy(37);
    margin-top: 60px;
  }
}

ul.boxLinkList {
  display: table;
  width: percentage(12 / 20);

  @include linkPrimaryColor();

  @include bp(mobile) {
    margin: 0;
    width: 100%;
  }

  li {
    list-style: none;
    margin-bottom: 1em;
    display: block;

    @include boxLinkWithIcon();

    dl {
      margin-bottom: 30px;
      vertical-align: middle;

      dt {
        line-height: 90px;
        height: 90px;
        vertical-align: middle;
      }
    }

    svg {
      width: 19px;
      height: 25px;

      &.svg-docicongreen-new {
        background: url('../images/svg/docicongreen.svg');
        background-position: left top;
        background-repeat: no-repeat;
        background-size: 19px;
      }

      &.svg-exlinkicon-new {
        background: url('../images/svg/exlinkicon.svg');
        background-position: left top;
        background-repeat: no-repeat;
        background-size: 19px;
      }
    }
  }
}

/* Thumbnail with SVG (Portfolio, Blog Category) */

.thumbnail-with-svg,
.t-thumbnail-with-svg  {
   position: relative;
   display: block;

  .thumbnail-cover-on,
   .thumbnail-cover-on-inner,
   .t-thumbnail-cover-on-inner {
     cursor: pointer;
   }

   .thumbnail-cover-on {
    // display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 35px;
    opacity: 0;
    transition: opacity .2s ease-out;

     .thumbnail-cover-on-inner,
     .t-thumbnail-cover-on-inner {
       background: $brand-primary-transparent;
       width: 100%;
       height: 100%;

       // animation: alpha0to1 0.3s $ease-in-sine;

       svg {
         @include abs(50%, 50%, auto, auto);
         // transform: translate(50%, -50%) causes icon cut-off
         transform: translate(50%, -23px);
       }
     }

     @include bp(mobile-tablet) {
      .mobile & {
        display: block;
        opacity: 0;
        padding: 0;
      }
     }
   }

   &:hover {
     .thumbnail-cover-on {
      // display: block;
      opacity: 1;
      transition: opacity .4s ease-out;
    }

   }
 }


/* Parallax with skrollr */
.services-section {
  position: relative;

  &.service-1 {
    .parallax-wrapper-services {
      display: none;
    }
  }
}

.skrollr-desktop body {
  height:100% !important;
}

.parallax-wrapper {
  position:fixed;
  left:0;
  width:100%;
  overflow:hidden;
}

.parallax-wrapper-800 {
  height: 800px;
}

.parallax-wrapper-services {
  height: 500px;
  position: absolute;
  z-index: -1;
}

.parallax-wrapper-services-block {
  background-color: $brand-primary-transparent;
  height: 500px;
}


.parallax-image {
  //display: none;
  bottom:0;
  left:0;
  width:100%;
  height: 100%;
  @include bgFit();
}

.parallax-image.skrollable-between {
  display:block;
}

.no-skrollr .parallax-wrapper {
  display:none !important;
}

#skrollr-body {
  height:100%;
  overflow:visible;
  position:relative;
}

//faq


.grid-thumbnail {
  .page-template-content-page-company-about &, .page-template-content-page-company-history & {
    display: none;
    max-width: 1194px;

    @include bp(mobile-tablet) {
      width: auto;
      max-width: 100%;
      margin: 0 30px;
    }

    .grid-sizer,
    .gutter-sizer {
      display: none;
    }
    .gutter-sizer {
      width: 0;
    }

    &.is--active {
      display: flex;
      flex-wrap: wrap;
    }
    .grid-item {
      width: 33.333%;
      padding: 0 10px;
      min-height: 415px;
      &.grid-item-inner p {
        width: auto;
        margin-left: 25px;
      }

      @include bp(mobile-tablet) {
        width: 50%;
        margin: 0 0 45px;
      }

      @include bp(mobile) {
        width: 100%;
        margin: 0 0 45px;
      }

    }

    img {
      width: 100%;
      display: block;
    }
    .t-thumbnail-with-svg {
      margin-bottom: 25px;
    }
    .portfolio-link {
      margin-bottom: 10px;
      display: inline-block;
    }
  }
}



.lightbox .lightbox-inner .lightbox-inner-box {
  .page-template-content-page-company-about &, .page-template-content-page-company-history & {
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap;
    max-height: 80%;
    max-width: 1076px;
    width: 80%;
    padding: 0 30px;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    margin: 0 auto;

    @include bp(mobile-tablet) {
      position: absolute;
      padding: 0 70px;
    }
    @include bp(mobile) {
      position: absolute;
      padding: 0 25px;
    }
    > div {
      width: 50%;
      &:last-child(){
        width: 50%;
        padding-right: 70px;
        color: #fff;
      }

      h3 {
        @include remy(26);
        margin-bottom: 10px;
      }

      span.position {
        display: block;
        margin-bottom: 30px;
      }

      img {
        width: 100%;
        height: auto;
        position: static;
        display: block;
        transform: translateX(0) translateY(0) translateZ(0);
      }
      @include bp(mobile) {
        width: 100%;
        margin-bottom: 30px;
        &:last-child(){
          width: 100%;
          padding-right: 0;
          color: #fff;
           margin-bottom: 0;
        }
      }
    }
  }
}

.video-module {
  .page-template-content-page-company-about &, .page-template-content-page-company-history &{
    //margin-top: -75px;
    position: relative;

    &:last-child {
      margin-bottom: 120px;
    }

    .play--button {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 998;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      cursor: pointer;

      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        display: none;
        circle {
          fill: #64A900;
        }
        polygon {
          fill: #fff;
        }
      }

      &:hover {
        // svg {
        //   display: block;
        // }
        svg circle {
          fill: #fff;
        }
        svg polygon {
          fill: #000;
        }
      }

      &.is--pause {
        svg {
          display: block;
        }
        polygon {
          fill: #fff;
        }
      }
    }

  }
}
.embed-container {
  position: relative;
  z-index: 1;
  .page-template-content-page-company-about &,.page-template-content-page-company-history & {
    position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%;
    iframe, object, embed {
      position: absolute; top: 0; left: 0; width: 100%; height: 100%;
    }
  }
}