/* Company */

body.parent-pageid-34,
body.parent-pageid-38,
body.page-template-page-flexible {
  main {
    color: $brand-primary;

    @include linkPrimaryColor();

    .company-intro,
    #about-leadership,
    #about-responsibility,
    #about-history,
    .carbon-steps {
      color: $brand-secondary;
    }
  }
}

body.about-us {
  main {
  }
}

body.sustainability,
body.carbon-neutrality-zero,
body.technology,
body.recognition {
  main {
    @include bp(mobile) {
      padding-bottom: 3em;
    }
  }
}

.company-menu-container {
  width: 100%;
  position: fixed;
  top: 70px;
  background: #fff;
  padding: 20px 0 24px;
  z-index: 999999;

  a {
    color: $brand-primary;
  }

  .is--cookie__notaccepted & {
    top: 130px;
  }

  @include bp(mobile-tablet) {
    display: none;
  }
}
#company-menu-list {
  // display: table;
  width: 60%;
  margin: 0 auto;
  padding-top: 0;
  padding-right: 0;
  text-align: center;

  li {
    display: inline;
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }

    @include hoverUnderLine();
  }

  @include bp(mobile-tablet) {
    display: none;
  }
}

.company-title {
  h1 {
    margin: 203px auto 123px; //93px auto 123px;
    text-align: center;
    @include remy(78);
    line-height: percentage(76/78);

    @include bp(mobile-tablet) {
      width: 100%;
      @include remy(48);
      line-height: percentage(94/96);
      // text-align: left;
      margin: 135px auto 55px;

      // .is--cookie__notaccepted & {
      //   padding-top: 140px;
      // }

    }

    .is--cookie__notaccepted & {
      margin: 230px auto 123px; 
    }
  }
}

// body.carbon-neutrality-zero {
//   .company-title {
//     h1 {
//       // margin: 118px auto 60px;

//       @include bp(mobile-tablet) {
//         // margin: 118px auto 40px;
//       }
//     }

//     svg {
//       display: block;
//       margin: 0 auto 110px;

//       @include bp(mobile-tablet) {
//         margin: 0 auto 55px;
//       }
//     }
//   }
// }

.bg-image {
  position: absolute;
  @include bgFitFull();

  @include bp(mobile-tablet) {
    @include bgFitFull(305px);
  }
}

/* Common in company */

.company-box {
  background: $brand-primary-transparent;
  color: $brand-secondary;
  padding-top: 75px;

  @include linkSecondaryColor();

  @include bp(mobile-tablet) {
    // padding-top: 33px;
  }

  .grid-20 {
    display: table;

    .carbon-steps-link {
      @include linkSecondaryColor();
      @include linkUnderLine();

      padding-top: 20px;
    }
  }

  &#about-leadership .grid-20 {
    display: block;
  }

  p.subTitle {
    line-height: 1;
    margin: 0 0 20px;

    // @include bp(mobile-tablet) {
    //   margin: 0 0 10px;
    // }
  }

  p.mainTitle {
    @include fontStyle2();
    @include remy(38);
    line-height: percentage(44/38);

    @include bp(mobile-tablet) {
      @include remy(21);
      line-height: 26px;
      padding-top: 13px;
      margin-bottom: 43px;
    }
  }

  h2 {
    @include remy(80);
    line-height: percentage(80/78);

    @include bp(mobile) {
      @include remy(38);
    }
  }
}

.company-intro {
  position: relative;
  // margin-top: -220px;
  padding-bottom: 108px;
  margin-bottom: 85px;

  // sustainability-intro
  &#sustainability-intro,
  &#carbon-intro,
  &#technology-intro {
    padding-bottom: 196px;

    .page-template-page-flexible & {
      padding-bottom: 165px;
    }

    @include bp(mobile-tablet) {
      padding-bottom: 60px !important;
    }
  }

  @include bp(mobile-tablet) {
    padding-bottom: 60px !important;
  }

  .mobile & {
      margin-bottom: 57px;
      padding-bottom: 30px;
      margin-top: -170px!important;
  }

  .grid-20 {
    .company-hero-left,
    .company-hero-right {
      display: inline-block;
      vertical-align: top;
      
      &.text-center {
			text-align: center;
      }
    }

    .company-hero-right {
      float: right;
    }
    .company-hero-left {
      width: percentage(15 / 20);

      @include bp(mobile-tablet) {
        width: 100%;
      }

      h2 {
        @include remy(80);
        line-height: percentage(80/78);

        @include bp(mobile-tablet) {
          @include remy(37);
          margin-bottom: 26px;

          br {
            display: none;
          }
        }
      }
    }

    .company-hero-left {

      p.mainTitle {
        margin-bottom: 57px;
      }
      @include linkSecondaryColor();
      @include linkUnderLine();
      @include linkWithIcon();
    }

    .page-template-page-flexible & {

      .company-hero-right {
        padding-top: 30px;
        width: 69%;
        margin-left: 0;
        @include bp(mobile-tablet) {
          width: 100%;
        }
      }

      .company-hero-right .two--col__text {
        display: flex;
        margin: 0 -25px 50px;
        > div {
          width: 50%;
          padding: 0 25px;
        }
        @include bp(mobile-tablet) {
          margin: 0 0 30px;
          display: block;
          > div {
            width: 100%;
            padding: 0 0 30px;
          }
        }
      }

      .company-hero-right .two--col__list {
        -webkit-column-count: 2;
        column-count: 2;
        -webkit-column-gap: 50px;
        column-gap: 0;
        margin: 0 -50px 0 0;
        counter-reset: a;
        column-width: 50%;
        min-height: 1px;
        li {
          width: calc(100% - 100px);
          padding: 0 0 0 0;
          margin-bottom: 30px;
          counter-increment: my-awesome-counter;
          position: relative;
          -webkit-column-break-inside: avoid;
          page-break-inside: avoid;
          break-inside: avoid;
          min-height: 1px;

          &::before {
            position: absolute;
            top: 0;
            left: -20px;
            transform: translateX(-200%);
            content: "0" counter(my-awesome-counter);
          }
          &::after {
            content: '';
            position: absolute;
            top: 10px;
            left: -10px;
            transform: translateX(-100%);
            width: 20px;
            height: 1px;
            background: #fff;
          }
        }
        @include bp(mobile-tablet) {
          column-count: 1;
          margin: 0;
          display: block;
          margin: 0 0 0 50px;
          > li {
            padding: 0;
            width: 100%;
          }
        }
      }
    }


    .company-hero-right {
      margin-left: percentage(1 / 20);
      width: percentage(6 / 20);
      padding-top: 30px;

      @include bp(mobile-tablet) {
        padding-top: 0 !important;
        margin-left: 0;
        width: 100%;
      }

      p.subText {
        margin-bottom: 57px;

        @include bp(mobile-tablet) {
          // margin-bottom: 1em;
        }

      }

      p.mainText {
        @include fontStyle2();
        @include remy(26);
        line-height: percentage(34/26);
        margin-bottom:26px;

        @include bp(mobile-tablet) {
          @include remy(21);
          // margin-bottom: 2em;
        }
      }

      @include linkSecondaryColor();
      @include linkUnderLine();
      @include linkWithIcon();
    }

    .bottom {
      margin-left: percentage(14 / 20);
      width: percentage(6 / 20);

      @include bp(mobile-tablet) {
        margin-left: 0;
        width: 100%;
        padding-top: 30px;
      }

      p.subText {
        @include bp(mobile-tablet) {
        }
      }
    }

  }
}

.parallax-image {
  & + .company-two-columns,
  & + .company-box {
    margin-top: 60px;
  }
}



.single-column-content {
  margin-bottom: 80px;
  // padding-bottom: 83px;

  @include bp(mobile-tablet) {
    margin-bottom: 56px;
    padding-bottom: 0;
  }

  .grid-20 {
    display: flex;
    flex-wrap: wrap;
  }

  .left,
  .right {
    vertical-align: top;
    //display: table-cell;

    @include bp(mobile-tablet) {
      display: block;
      width: 100%;
    }
  }

  .left {
    width: 100%;
    //padding-right: percentage(3 / 20);
    padding-right: 66%;
    @include bp(mobile-tablet) {
      width: 100%;
      padding-right: 0;
      margin-bottom: 50px;
    }

    h2 {
      @include remy(38);
      line-height: 1.157;

      @include bp(mobile-tablet) {
        @include remy(27);
        margin-bottom: 3rem;
      }
    }

    p.subTitle {
      line-height: 1;
      margin: 0 0 1em;
    }
  }

  .right {
    width: 100%;
    padding-left: 50%;
    @include bp(mobile-tablet) {
      width: 100%;
      margin-left: 0;
      padding-top: 0;
      padding-left: 0;
    }
  }

  .page-template-page-flexible & {

    ul {
      display: grid;
      @include clearfix;
      margin: 0 -5%;
      li {
        float: left;
        width: 100%;
        margin: 0;
        padding: 0 5% 70px;

        display: flex;
        align-items: center;

        > span.imagethumbnail {
          display: block;
          width: 25%;
          max-width: 70px;
          margin-right: 100px;

          img {
            margin: 0 auto;
          }
        }
        > span.text {
          display: block;
          width: 75%;
          @include remy(18);
        }
        @include bp(mobile-tablet) {
          > span.imagethumbnail {
            margin-right: 20px;
          }
        }
      }

      h3 {
        @include remy(38);
        margin: 0 0 25px;
        line-height: percentage(44/38);
        @include bp(mobile-tablet) {
          @include remy(28);
        }
      }

      h4 {
        @include fontStyle();
        @include remy(16);
        font-weight: 500;
        margin-bottom: 15px;
        margin-top: 20px;
        line-height: 22px;
      }

      p {
        margin-bottom: 25px;
        font-weight: 500;
      }

      @include bp(mobile-tablet) {
        padding-left: 0;
        margin: 0;

        li {
          width: 100%;
          margin: 0 0 28px !important;
          padding: 0;
          padding-bottom: 0;
        }
        li:first-child {
          float: left;
          margin: 0 0 28px;
          padding-bottom: 0;
        }
      }
    }

  }


}

.brand-pillar {
	width: 100%;
	margin: 0px auto;
	padding: 0px;
	text-align: center;
	
	
	li {
		padding: 100px 120px;
		border-bottom: 1px solid $brand-primary;
		@include bp(mobile-tablet) {
			padding: 50px 40px;
		}
		
		&:last-of-type{
			border-bottom: none;
		}
	}
	
	 p.subTitle {
	    line-height: 1;
	    margin: 0 0 20px;

    // @include bp(mobile-tablet) {
    //   margin: 0 0 10px;
    // }
  }

  p.mainTitle {
    @include fontStyle2();
    @include remy(72);
    line-height: percentage(44/38);

    @include bp(mobile-tablet) {
      @include remy(21);
      line-height: 26px;
      padding-top: 13px;
      margin-bottom: 43px;
    }
  }

  h2 {
    @include remy(80);
    line-height: percentage(80/78);

    @include bp(mobile) {
      @include remy(38);
    }
  }
}

.diversity {
	
  
  
}

.company-two-columns, .timeline {
  // padding-bottom: 30px;
  margin-bottom: 80px;
  // float: left;
  
  .col {
	  display: inline-grid;
	    width: 45%;
    }

  @include linkUnderLine();

  @include bp(mobile-tablet) {
    padding-bottom: 0;
    margin-bottom: 40px;

    &#sustainability-text {
      ul {
        li {
          margin-bottom: 0;
        }
      }
      .item {
        margin-bottom: 66px;
      }
    }

  }

  a {
    display: inline-block;
    // margin-bottom: 1rem;

    &:after {
      bottom: 0px;
    }
    
    
    
  }




  .page-template-page-flexible & {

    &.numbered--list {
      margin-bottom: 0;
      .grid-20 {
        padding-right: 50px;
      }
      @include bp(mobile-tablet) {
        .grid-20 {
          padding-right: 0;
        }
      }
    }

    .grid-20 {
      padding-left: 33%;
      width: auto;
      @include bp(mobile-tablet) {
        padding-left: 0;
        width: 85.3372434018%;
      }
    }


    ul.numberedlist {
      -webkit-column-count: 2;
      column-count: 2;
      -webkit-column-gap: 50px;
      column-gap: 0;
      margin: 0 -50px 0 0;
      counter-reset: a;
      column-width: 50%;
      display: block;

      @include bp(mobile-tablet) {
        column-count: 1;
        column-width: 100%;
        display: block;
        margin: 0 0 25px 0;
        width: 100%;
      }

      li {
        width: 100%;
        padding: 0 50px 0 50px;
        margin-bottom: 0;
        counter-increment: a;
        position: relative;
        -webkit-column-break-inside: avoid;
        break-inside: avoid;

        &:first-child {
          float: none;
        }

        @include bp(mobile-tablet) {
          display: block;
          width: 100%;
          position: relative;
          margin-bottom: 30px;

        }

        &::before {
          position: absolute;
          top: -4px;
          left: 0;
          transform: translateX(0);
          content: "0" counter(a);
          font-weight: 500;
          @include remy(16);
          @include fontStyle();
        }

        &::after {
          content: "";
          position: absolute;
          top: 8px;
          left: 27px;
          width: 15px;
          height: 1px;
          background: $brand-primary;
        }

        h3 {
          @include remy(16);
          @include fontStyle();
          margin-bottom: 10px;
        }
      }
    }


    ul {
      display: grid;
      @include clearfix;
      margin: 0 0;
      grid-template-columns: 50% 50%;
      li {
        // float: left;
        width: 100%;
        margin: 0;
        padding: 0 50px 70px 0;
        grid-column-start: 2;

        p {
          @include remy(14);
          font-weight: 500;
          @include bp(mobile-tablet) {
            &:last-child {
              margin-bottom: 0;
            }
          }
        }

        strong {
          font-weight: 500;
          @include remy(16);
        }

      }
      li:nth-child(odd){
        // float: left;
        margin: 0;
        padding-bottom: 0;
        grid-column-start: 1;
        grid-row-end: span 10;
      }
      @include bp(mobile-tablet) {
        padding-left: 0;
        margin: 0;
        display: block;

        li {
          width: 100%;
          margin: 0 0 25px !important;
          padding-bottom: 0;
          padding: 0;
        }
        li:first-child {
          float: left;
          margin: 0 0 25px;
          padding-bottom: 0;
        }
      }
    }

  }

  h3 {
    @include remy(38);
    margin: 0 0 25px;
    line-height: percentage(44/38);
    @include bp(mobile-tablet) {
      @include remy(28);
    }
  }

  h4 {
    @include fontStyle();
    @include remy(16);
    font-weight: 500;
    margin-bottom: 15px;
    margin-top: 20px;
    line-height: 22px;
  }

  p {
    margin-bottom: 25px;
  }

  ul {
    display: table;

    @include bp(mobile-tablet) {
      margin-bottom: 2px;
    }

    li {
      display: inline-block;
      vertical-align: top;
      width: percentage(6 / 20);
      margin-top: 0;

      @include bp(mobile-tablet) {
        width: 100%;
        margin-bottom: 28px;
      }

      &:first-child {
        margin-left: percentage(6.9 / 20);
        margin-right: percentage(1 / 20);

        @include bp(mobile-tablet) {
          margin-left: 0;
          margin-right: 0;
        }
      }

      .item {
        padding-bottom: 0;
        margin-top: 70px;

        h3 {
          @include remy(38);
          margin: 0 0 25px;
          line-height: percentage(44/38);
          @include bp(mobile-tablet) {
            @include remy(28);
          }
        }

        h4 {
          @include fontStyle();
          @include remy(16);
          font-weight: 500;
          margin-bottom: 15px;
          margin-top: 20px;
          line-height: 22px;
        }

        p {
          margin-bottom: 25px;
        }

        @include bp(mobile-tablet) {
          padding-bottom: 0;
          margin: 0;
        }
      }
    }
  }
}

/* About */

#about-leadership {
  @include clearfix();
  padding-top: 60px;
  padding-bottom: 30px;
  margin-bottom: 190px;

  @include bp(mobile) {
    padding-top: 45px;
    margin-bottom: 62px;
  }

  h2 {
    line-height: 1;
    margin-bottom: 0;

    @include bp(mobile) {
      margin-bottom: 35px;
    }
  }

  @include linkUnderLine;

  a {
    color: #fff;
  }

  .grid-20 {
    width: 100%;
    padding-left: 50px;
    @include bp(mobile) {
      padding-left: 0;
      width: 85.3372434018%;
    }
  }

  .about-leadership-inner {
    // margin-left: percentage(7 / 20);
    // width: percentage(11 / 20);
    display: flex;
    flex-wrap: wrap;
    margin-left: -50px;
    width: 66.66%;
    float: right;

    @include bp(mobile) {
      margin-left: 0;
      width: 100%;
    }
  }

  .about-leadership-item {
    width: 50%;
    padding-right: 50px;
    padding-bottom: 62px;

    @include bp(mobile) {
      float: none;
      width: 100%;
      padding-bottom: 26px;
    }

    // &:nth-child(odd) {
    //   margin-right: percentage(1 / 13);
    // }

    h3 {
      @include remy(26);
      margin: 20px 0 5px;

      @include bp(mobile) {
        @include remy(23);
      }
    }

    p {
      margin-bottom: 1.5rem;
    }
  }
}

#about-leadership-pc {
  padding-top: 130px;
}

#about-leadership-mobile {
  .accordion-menu {
    width: percentage(13 / 20 );
    margin-left: -13.428px;
    padding-bottom: 25px;

    @include bp(mobile-tablet) {
      svg.svg-pluswhite-new {
        top: 22px;
        display: block;
        background: url('../images/svg/pluswhite.svg');
        background-position: left top;
        background-repeat: no-repeat;
      }

      svg.svg-minuswhite-new {
        top: 28px;
        display: none;
        background: url('../images/svg/minuswhite.svg');
        background-position: left top;
        background-repeat: no-repeat;
      }
    }

    @include bp(mobile) {
      margin-left: 0;
      width: 100%;
    }

    dt {
      position: relative;
      cursor:pointer;
      @include fontStyle();
      @include remy(26);

      padding-left: 20px;

      @include bp(mobile-tablet) {
        margin-left: 0;
        width: 100%;
      }

      h3 {
        @include remy(23);
        margin-bottom:3px;
        padding-bottom:0;
      }

      p {
        @include remy(14);
        margin-bottom:20px;
        padding-bottom:0;
      }

      svg {
        position: absolute;
        left: 0px;
      }

      svg.svg-pluswhite-new, svg.svg-pluswhite {
        top: 12px;
        display: block;
      }

      svg.svg-minuswhite-new, svg.svg-minuswhite {
        top: 18px;
        display: none;
      }

      &.active {
        svg.svg-pluswhite-new, svg.svg-pluswhite {
          display: none;
        }

        svg.svg-minuswhite-new, svg.svg-minuswhite {
          display: block;
        }
      }

    }

    dd {
      display:none;
      padding-left: 20px;

      p {
        margin: 1em 0;
      }
    }
  }
}

#about-responsibility {
  position: relative;
  display: inline-block;
  width: 100%;

  .company-inner {
    position: relative;
    // margin-top: 246px;
    margin-bottom: 80px;
    padding-bottom: 100px;

    @include bp(mobile-tablet) {
      margin-bottom: 66px;
      padding-bottom: 75px;
      // margin-top: -170px;
    }

    .mobile & {
        margin-bottom: 66px;
        padding-bottom: 75px;
        margin-top: -170px;
    }

    .grid-20 {
	    
	    .center {
		    width: 90%;
		    margin: 0px auto;
		    text-align: center;
	    }
      .company-left,
      .company-right {
        vertical-align: top;
      }

      .company-left {
	      display: inline-block;
        width: percentage(9 / 20);

        @include bp(mobile-tablet) {
          width: 100%;
        }

        p.mainText {
          margin-bottom: 65px;

          @include bp(mobile-tablet) {
            margin-bottom: 50px
          }
        }
      }
      .company-right {
	       display: inline-block;
        margin-left: percentage(1 / 20);
        width: percentage(9 / 20);

        @include bp(mobile-tablet) {
          margin-left: 0;
          width: 100%;
        }

        //ul {
        //  //display: table;
		//
        //  li.item {
        //    vertical-align: top;
        //    display: inline-block;
        //    width: percentage(4.9 / 12);
		//
        //    @include bp(mobile-tablet) {
        //      width: 100%;
        //    }
		//
        //    &:last-child {
        //      margin-left: percentage(2 / 12);
        //      // float:right;
		//
        //      @include bp(mobile-tablet) {
        //        margin-left: 0;
        //      }
        //    }
		//
        //    ul {
        //      li {
        //        display: block;
        //        @include strikeLineLeft(-23px, 11px, 18px);
        //        position: relative;
        //        margin-bottom: 1rem;
        //        // @include breakWord();
        //        span {
        //          @include abs(0, auto, 0, -45px);
        //          position: absolute;
        //        }
		//
        //        @include bp(mobile-tablet) {
        //          @include strikeLineLeft(20px, 11px, 16px);
        //          padding-left: 39px;
		//
        //          span {
        //            @include abs(0, auto, 0, 0px);
        //          }
        //        }
        //      }
        //    }
        //  }
        //}
        
    	}
    	
    	.responsibilities {
	    	li {
		    	position: relative;
		    	padding: 0px 20px;
		    	margin: 0px 0px 12px;
		    	
		    	&:before {
			    	position: absolute;
			    	display: inline-block;
			    	content:'\2014';
			    	left: 0px;
		    	}
	    	}
    	}
    }
  }
}

#about-history {
  position: relative;
  display: block;
  width: 100%;
  height: auto;
  overflow: auto;
  padding-bottom: 100px;
  margin-bottom: 100px;
  
 
  // height: 931px;

  @include bp(mobile-tablet) {
    height: 465px;
  }

  @include bp(mobile) {
    height: 500px;
  }

  .fit-bg-image {
    position: absolute;
    @include bgFitFull(100%);

    @include bp(mobile-tablet) {
      @include bgFitFull(465px);
    }
    @include bp(mobile) {
      @include bgFitFull(500px);
    }
  }

  & + .grid-22 #page-intro-box {
    padding-top: 0;
    padding-bottom: 0;
  }


  .company-inner {
    position: relative;

    .grid-20 {
	    
	  .subTitle {
		  position: relative;
		  margin: 80px 0px 0px;
  		}
      h2 {
        @include remy(72);
        margin-top: 0px;
        margin-bottom: 40px;

        @include bp(mobile-tablet) {
          @include remy(37);
          margin-top: 0px;
          margin-bottom: 20px;
        }
      }

      ul.history-list {
        display: table;

        @include bp(mobile-tablet) {
          display: none;
        }

        li {
          vertical-align: top;
          display: inline-block;
          width: percentage(6 / 20);
          margin-right: percentage(0.9 / 20);
          padding-bottom: 1em;

          &:nth-child(3n) {
            margin-right: 0;
          }

          p.history-title {
            margin: 0 0 10px;
            @include fontStyle2();
            @include remy(26);
          }
        }
      }

      ul.history-list-mobile {
        position: relative;
        width: 100%;

        display: none;

        @include bp(mobile-tablet) {
          display: block;
        }

        li {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          margin-right: 0;


          p.history-title {
            @include bp(mobile-tablet) {
              @include remy(48);
              margin-bottom: 11px;
            }
          }
        }
      }

      ul.history-tab-mobile {
        display: none;

        position: absolute;
        top: 340px;

        @include bp(mobile-tablet) {
          display: block;

          li {
            display: inline;
            margin-right: 15px;
            line-height: 2;
            @include linkSecondaryColor;

            a.active {
              position: relative;
              // text-decoration: line-through;
              &:after {
                content: "";
                height: 1px;
                width: 28px;
                background: #fff;
                position: absolute;
                top: 50%;
                right: 50%;
                transform: translate(50%, -50%);
              }
            }

            a, a:visited {
              color: $brand-secondary;
            }
          }
        }
      }
    }
  }
}


#about-diversity {
  position: relative;
  display: inline-block;
  width: 100%;

  .diversity {
    position: relative;
    // margin-top: 246px;
    margin-bottom: 80px;
    padding-bottom: 100px;

    @include bp(mobile-tablet) {
      margin-bottom: 66px;
      padding-bottom: 75px;
      // margin-top: -170px;
    }

    .mobile & {
        margin-bottom: 66px;
        padding-bottom: 75px;
        margin-top: -170px;
    }

    .grid-20 {
	    
	    .center {
		    width: 90%;
		    margin: 0px auto;
		    text-align: center;
	    }
      .grid-20 {
		    margin: 0;
		    width: 100%;
		    padding: 0 0 0 50px;
		  }

	  .left,
	  .right, .col {
	    vertical-align: top;
	    display: inline-block;
	
	    @include bp(mobile-tablet) {
	      display: block;
	      width: 100%;
	    }
	  }
	  
	.left, .col {
	  width: 33%;
	  margin-left: 15%;
	    @include bp(mobile-tablet) {
	      width: 100%;
	      margin-left: 0px;
	    }

	    h2 {
	      @include remy(38);
	      line-height: 1;
	
	      @include bp(mobile-tablet) {
	        @include remy(27);
	        margin-bottom: 3rem;
	      }
	    }
	
	    p.subTitle {
	      line-height: 1;
	      margin: 0 0 1em;
	    }
	  }

     
    }
     .col:last-of-type {
	    margin-left: 40px;
	    width: 33%;
	    padding-top: 0;
	
	    @include bp(mobile-tablet) {
	      width: 100%;
	      margin-left: 0;
	      padding-top: 0;
	    }
	}
	
	 h2 {
	 	@include remy(72);
	    line-height: percentage(80/72);
	    margin-bottom: 30px;
	
	    @include bp(mobile) {
	      @include remy(38);
	    }
	  }
	  
	  ul {
		  li {
		    	position: relative;
		    	padding: 0px 30px;
		    	margin: 0px 0px 12px;
		    	
		    	&:before {
			    	position: absolute;
			    	display: inline-block;
			    	content:'\2014';
			    	left: 0px;
		    	}
	    	}
	  }
  }
}


.sustainable-icon {
	max-width: 100px;
	
}

.sustainable-icon-md {
	max-width: 200px;
	//margin: 0px 0px 50px!important;
}

.sustainable-icon-large {
	width: 100%;
}

.sustainability-partners {
  margin-bottom: 165px;
  padding-bottom: 83px;

  .page-template-page-flexible & {
    margin-bottom: 0;
    .grid-20 {
      padding-left: 0;
    }
    .left {
      padding-left: 50px;
    }
    .right {
      dl {
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
      }
      dt {
        width: 100%;
        margin-bottom: 50px;
        height: 70px;
        display: flex;
        align-items: flex-end;
      }
      dd {
        width: 100%;
      }
    }
    @include bp(mobile-tablet) {
      .grid-20 {
        width: 85.3372434018%;
        margin: 0 auto;
      }
      .left {
        padding-left: 0;
      }
      .right {
        li {
          padding-right: 0;
        }
      }
    }
  }

  @include bp(mobile-tablet) {
    margin-bottom: 56px;
    padding-bottom: 0;
  }

  .grid-20 {
    margin: 0;
    width: 100%;
    padding: 0 0 0 50px;
  }

  .left,
  .right {
    vertical-align: top;
    display: table-cell;

    @include bp(mobile-tablet) {
      display: block;
      width: 100%;
    }
  }

  .left {
    width: 33%;

    @include bp(mobile-tablet) {
      width: 100%;
    }

    h2 {
      @include remy(38);
      line-height: 1;

      @include bp(mobile-tablet) {
        @include remy(27);
        margin-bottom: 3rem;
      }
    }

    p.subTitle {
      line-height: 1;
      margin: 0 0 1em;
    }
  }

  .right {
    margin-left: 33%;
    width: auto;
    padding-top: 0;

    @include bp(mobile-tablet) {
      width: 100%;
      margin-left: 0;
      padding-top: 0;
    }

    ul {
      display: flex;
      flex-wrap: wrap;

      li {
        vertical-align: top;
        display: inline-block;
        width: 50%;
        padding: 0 50px 50px 0;

        @include bp(mobile-tablet) {
          width: 100%;
        }

        // &:nth-child(2n) {
        //   margin-left: percentage(0.9 / 13);

        //   @include bp(mobile-tablet) {
        //     margin-left: 0;
        //   }
        // }

        &:first-child {
          svg {
            margin-top: 30px;

            @include bp(mobile-tablet) {
              margin-top: 0;
            }
          }
        }

        &:nth-child(3) {
          svg {
            margin-top: 24px;

            @include bp(mobile-tablet) {
              margin-top: 0;
            }
          }
        }

        // &:first-child, &:nth-child(2) {
        //   dt {
        //     height: 95px;

        //     @include bp(mobile-tablet) {
        //       height: auto;
        //     }
        //   }
        // }


        // &:nth-child(3), &:nth-child(4) {
        //   dt {
        //     height: 110px;

        //     @include bp(mobile-tablet) {
        //       height: auto;
        //     }
        //   }
        // }

        // &:nth-child(5), &:nth-child(6) {
        //   dt {
        //     height: 115px;

        //     @include bp(mobile-tablet) {
        //       height: auto;
        //     }
        //   }
        // }

        // dl {
        //   vertical-align: top;
        //   margin-bottom: 54px;

        //   dt {
        //     height: 120px;
        //     vertical-align: bottom;
        //     display: table-cell;
        //     padding-bottom: 48px;

        //     @include bp(mobile-tablet) {
        //       margin-bottom: 22px;
        //       height: auto;
        //     }
        //   }

        //   dd {
        //     @include bp(mobile-tablet) {
        //       margin-bottom: 0;
        //     }
        //   }
        // }
      }
    }
  }
}

.sustainability-resources {
  @include bp(mobile-tablet) {
    margin-bottom: 0;
  }
}

/* Carbon */

.carbon-steps {
  position: relative;
  display: inline-block;
  width: 100%;

  .company-inner {
    position: relative;
    margin-top: 318px;
    padding-bottom: 100px;

    @include bp(mobile-tablet) {
      padding-bottom: 50px;
    }

    .mobile & {
        margin-top: -170px;
        padding-bottom: 50px;
    }

    .grid-20 {
      .company-left,
      .company-right {
        vertical-align: top;
      }

      .company-left {
        width: percentage(8 / 20);

        @include bp(mobile) {
          width: 100%;
        }
      }

      .company-right {
        margin-left: percentage(13 / 20);
        width: percentage(7 / 20);

        @include bp(mobile) {
          margin-left: 0;
          width: 100%;
        }

        dl {
          margin-bottom: 30px;

          @include bp(mobile) {
            margin-bottom: 0;
          }

          dt {
            @include fontStyle2();
            @include remy(23);
            @include strikeLineLeft(-21px, 20px, 18px);
            margin-bottom: 5px;;

            span {
              @include remy(16);
              @include fontStyle();
              @include abs(10px, auto, 0, -42px);
              position: absolute;
            }

            @include bp(mobile-tablet) {
              @include strikeLineLeft(22px, 20px, 16px);
              padding-left: 40px;

              span {
                @include abs(10px, auto, 0, 0px);
              }
            }
          }

          dd {
            //margin-left: 2em;
            margin-bottom: 30px;
          }
        }
      }
    }
  }
}

/* Technology */
#technology-text {
  .tech-list {
    @include bp(mobile-tablet) {
      margin-bottom: 0;
      padding-top: 0;
    }

    .tech-list-item {
      h2, p {
        width: percentage(6 / 20);

        @include bp(mobile) {
          width: 100%;
        }
      }

      h2 {
        @include remy(48);
        line-height: 1;
        margin: 0 0 30px;

        @include bp(mobile-tablet) {
          @include remy(37);
        }
      }

      ul {
        @include remy(18);
        display: table;
        margin-left: percentage(8 / 20);
        width: percentage(12 / 20);
        margin-bottom: 127px;
        line-height: 30px;

        @include bp(mobile) {
          margin-left: 0;
          width: 100%;
          margin-top: 3em;
          padding-top: 1.5em;
          border-top: solid 1px $brand-primary-light-line;
          margin-bottom: 60px;
        }

        li {
          border-bottom: solid 1px $brand-primary-light-line;
          margin-bottom: 1em;
          padding-bottom: 1em;

          .tech-list-subtitle {
            @include remy(14);
            display: block;
            padding: 0 0 5px;
          }

          @include bp(mobile) {
            margin-bottom: 1.5em;
            padding-bottom: 1.5em;
            line-height: 28px;
          }
        }
      }
    }
  }
}


/* Recognition */

#recognition-history{
  padding-top: 30px;
  background-color: $brand-secondary;

  .history-list {
    margin-bottom: 125px;

    @include bp(mobile) {
      margin-bottom: 0;
    }

    .history-list-year {
      display: table;
      width: 100%;
      margin-bottom: 90px;

      @include bp(mobile) {
        margin-bottom: 0;
        margin-top: 10px;
      }

      h2, .company-right {
        vertical-align: top;
        display: inline-block;
      }

      h2 {
        @include remy(80);
        width: percentage(4 / 20);
        line-height: .5;

        @include bp(mobile) {
          @include remy(37);
          width: 100%;
          line-height: 1;
        }
      }

      .company-right {
        margin-left: percentage(4 / 20);
        width: percentage(11 / 20);

        @include bp(mobile) {
          width: 100%;
          margin-left: 0;
        }

        .history-list-month {
          border-top: solid 1px $brand-primary-light-line;
          padding: 30px 0;

          h3 {
            padding: 0 0 20px;
            margin: 0;
            @include remy(38);
          }
          p {
            @include remy(18);
            line-height: 24px;
            margin: 0 0 20px;
          }
        }
      }
    }
  }
}

.award-link {
  @include linkUnderLine();
  @include linkWithIcon();
  margin-bottom: 175px;
}
.page-template-content-page-company-about .mobile-dropdown-list{
  span.first {
    position: relative;
    padding-left: 40px;
    white-space: nowrap;
    overflow: hidden;
    max-width: 86%;
  }
  span.first::before {
    content: '01';
    position: absolute;
    top: 0;
    left: 0;
  }
  span.first::after {
    content: '';
    width: 16px;
    height: 1px;
    background: #fff;
    display: block;
    position: absolute;
    top: 37px;
    left: 22px;
  }

  > li > ul, > li > ul> li {
    counter-increment: my-awesome-counter;
  }
  > li > ul > li {
    display: flex;
  }
  > li > ul > li::before {
    content: "0" counter(my-awesome-counter);
    top: -3px;
    position: relative;
  }
}


#page-intro-box.our-team__gallery {
  position: relative;
  //padding-top: 0;
  // &::before {
  //   content: '';
  //   position: absolute;
  //   top: 0;
  //   left: -50px;
  //   right: -50px;
  //   height: 1px;
  //   background: $brand-primary;
  // }

  #portfolio-menu-list {
    float: right;
    vertical-align: top;
    padding-top: 38px;
    counter-reset: my-awesome-counter;
    //width: 320px;

    @include bp(mobile) {
      display: none;
    }

    li {
      position: relative;
      @include fontStyle2();
      @include remy(26);
      counter-increment: my-awesome-counter;
      padding-left: 15px;
      line-height: 32px;

      span {
        display: inline-block;
        @include fontStyle();
        @include remy(16);
        vertical-align: middle;
        width: 22px;
      }
      @include hoverMidLine(40%, 18px);

      &::before {
        position: absolute;
        top: 0;
        left: -10px;
        transform: translateX(0);
        content: "0" counter(my-awesome-counter);
        @include fontStyle();
        font-weight: 300;
        @include remy(17);
      }
      a {
        padding-right: 0;
      }
    }
  }

}

#our-fifty #page-intro-box {
  padding-top: 0;
}

.company-timeline {
  padding-top: 30px;
  background-color: $brand-secondary;

  .history-list {
    margin-bottom: 125px;

    @include bp(mobile) {
      margin-bottom: 0;
    }

    .history-list-year {
      display: table;
      width: 100%;
      margin-bottom: 0px;
      
      &:last-of-type{
	       .history-list-month {
		    
		    &:last-of-type {
			    border-bottom: solid 1px $brand-primary-light-line;
		    }
	           
          }
      }

      @include bp(mobile) {
        margin-bottom: 0;
        margin-top: 10px;
      }

      h2, .company-right {
        vertical-align: top;
        display: inline-block;
      }

      h2 {
        @include remy(80);
        width: percentage(4 / 20);
        line-height: .5;

        @include bp(mobile) {
          @include remy(37);
          width: 100%;
          line-height: 1;
        }
      }

      .company-right {
        margin-left: percentage(4 / 20);
        width: percentage(11 / 20);

        @include bp(mobile) {
          width: 100%;
          margin-left: 0;
        }

        .history-list-month {
          border-top: solid 1px $brand-primary-light-line;
          padding: 30px 0;
          

          h3 {
            padding: 0 0 20px;
            margin: 0;
            @include remy(38);
          }
          p {
            @include remy(18);
            line-height: 24px;
            margin: 0 0 12px;
          }
          
          ul {
	          margin: 0px 0px 20px;
          }
        }
      }
    }
  }
}

.company-two-column-text {
	 position: relative;
  // margin-top: -220px;
  padding-bottom: 90px;
  margin-bottom: 85px;
  @include bp(mobile-tablet) {
	   padding-bottom: 0px;
	    margin-bottom: 0px;
  }
  .grid-20 {
  	.center {
	  	text-align: center;
	  	h3 {
	        @include remy(80);
	        line-height: percentage(80/78);

        @include bp(mobile-tablet) {
          @include remy(37);
          margin-bottom: 26px;

         
        }
      }
  	}
    .col {
	   display: inline-block;
	    width: 33%;
		margin-left: 15%;
		vertical-align: text-top;
	   	@include bp(mobile-tablet) {
        	width: 100%;
			margin-left: 0px;
		}
		
		&:last-of-type{
			margin-left: 5%;
			
			@include bp(mobile-tablet) {
				margin-left: 0px;
			}
		}
		
	}
		

	}
	
}

#sustainability-text {
	
.center {
	  	text-align: center;
	  	h3 {
	        @include remy(72);
	        line-height: percentage(72/78);
	        margin-bottom: 50px;

        @include bp(mobile-tablet) {
          @include remy(37);
          margin-bottom: 26px;
          }
      	}
  	}
  	
  	li {
	  	h3{
		  margin-top: 75px;
		&:first-of-type{
			margin-top: 0px;
		}
		  	
	  	}
  	}

	h4{
		font-size: 16px;
	    font-size: 1rem;
	    font-family: Whitney SSm A,Whitney SSm B,sans-serif;
	    font-weight: 500;
	    margin-bottom: 10px;
	}
	
	strong {
		font-weight: 600;
	}
}

.page-id-7141, .page-id-7179 {
	.company-intro .grid-20 .company-hero-left{
		
		&.text-center {
			width: 100%;
		}
		
	}
	
}

.social-purpose {
	.company-intro {
		 &#sustainability-intro,
		  &#carbon-intro,
		  &#technology-intro {
		    padding-bottom: 30px;
		
		    @include bp(mobile-tablet) {
		      padding-bottom: 30px !important;
		    }
		  }
	}
}

.mobile {
	#about-diversity {
		margin-top: 100px!important;
	}
}
