/* border */
body {
  &.home {
    @include addFooterBorder(70px);
    @include bp(mobile-tablet) {
      @include addFooterBorder(0);
    }
  }
  &.services {
    //@include addFooterBorder(133px);
    // @include bp(mobile-tablet) {
    //   @include addFooterBorder(20px);
    // }
  }
  &.employment,
  &.careers {
    @include addFooterBorder(140px);
    @include bp(mobile-tablet) {
      @include addFooterBorder(44px);
    }
  }
  &.get-a-quote {
    @include addFooterBorder(198px);
  }
  &.sustainability {
    @include addFooterBorder(160px);
    @include bp(mobile-tablet) {
      @include addFooterBorder(2px);
    }
  }
  &.carbon-neutrality-zero {
    @include addFooterBorder(0);
    @include bp(mobile-tablet) {
      @include addFooterBorder(12px);
    }
  }
  &.technology {
    @include addFooterBorder(130px);
    @include bp(mobile-tablet) {
      @include addFooterBorder(14px);
    }
  }
  &.about-us {
    @include addFooterBorder(0);
    @include bp(mobile-tablet) {
      @include addFooterBorder(14px);
    }
  }
  &.error404,
  &.search,
  &.page-default-container {
    @include addFooterBorder(170px);
  }
  &.page-id-58,
  &.page-id-56 {  // Prepare
    @include addFooterBorder(153px);

    @include bp(mobile-tablet) {
      @include addFooterBorder(61px);
    }
  }
  &.page-id-48 {  // FAQ
    @include bp(desktop-all) {
      @include addFooterBorder(165px);
    }

    @include bp(mobile-tablet) {
      footer {
        margin-top: 20px;
      }
    }
  }
  &.contact {
    @include addFooterBorder(175px);

    @include bp(mobile-tablet) {
      @include addFooterBorder(61px);
    }
  }
  &.page-id-54 {  // terms
    @include addFooterBorder(124px);
    @include bp(mobile-tablet) {
      @include addFooterBorder(40px);
    }
  }
  &.page-id-7189{
	  footer {
		  margin-top: -100px;
	  }
  }
  
  &.page-id-672 {
	  footer {
		  margin-top: -70px;
	  }
  }
  
  &.page-id-4240, &.single-post{
	   footer {
		  margin-top: 150px;
	  }
  }

}

footer.content-info {
  display: none;
  position: relative;

  color: $brand-primary;
  background: $brand-footer;

  @include linkPrimaryColor;

  .container {
    padding-top: 60px;
    padding-bottom: 40px;

    @include bp(mobile-tablet) {
      margin: 0px auto;
      width: $content-mobile-max-width;
      padding-top: 58px;
      // @include fz_vw(28);
    }

    #footer-top {
      display: table;
      margin: 0px auto;

      @include bp(mobile-tablet) {
        width: $content-mobile-inner-width;
      }

      .footer-left,
      .footer-right {
        display: inline-block;
        vertical-align: top;
        // float: left;
      }

      .footer-left {
        margin-right: percentage(4 / $grid-20);
        width: percentage(10 / 20);

        @include bp(mobile-tablet) {
          padding-top: 0;
          width: 100%;
        }
        
        .footer-hemlock-group,
        .footer-partners {
          width: percentage(4 / 8);
          float: left;

          @include bp(mobile-tablet) {
            width: 100%;
          }

          h3 {
            @include fontStyle();
            @include remy(14);
            margin-bottom: 1em;
            line-height: 22/16;

            @include bp(mobile-tablet) {
              // @include remy(28);
            }
          }
        }

        h2 {
          @include remy(48);
          line-height: 1;
          margin-bottom: 26px;

          @include bp(mobile-tablet) {
            width: 100%;
            @include remy(37);
            margin-bottom: percentage(58 / $content-mobile-max-width);
          }
        }

        ul {
          width: 100%;
          display: table;

          @include bp(mobile-tablet) {
            margin-bottom: percentage(100 / $content-mobile-max-width);
          }

          li {
            display: inline-block;
            width: 70%;
            margin: 12px 0px;
            
            .hemlock-harling{
	            width: 185px;
            }

            .hemlock-display{
              width: 130px;
            }
            
            .prismtech, .harling{
	            width: 125px;
            }
            
            .pdi {
	            width: 75px;
            }  
            
            .pdi {
	            width: 75px;
            }         
          }
        }
      }

      .footer-right {
        @include clearfix();
        width: percentage(6 / 20);
        margin-bottom: 95px;

        @include bp(mobile-tablet) {
          width: 100%;
          margin-bottom: percentage(108 / $content-mobile-max-width);
        }

        .footer-get-intouch,
        .footer-resource {
          width: percentage(2 / 5);
          float: left;

          @include bp(mobile-tablet) {
            width: 40%;
          }

          h3 {
            @include fontStyle();
            @include remy(14);
            margin-bottom: 1em;
            line-height: 22/16;

            @include bp(mobile-tablet) {
              // @include remy(28);
            }
          }

          ul {
            li {
              @include linkUnderLine();
              line-height: 28px;
            }
            
          }
        }

        .footer-get-intouch {
          //float: right;
          margin-right: percentage(4 / $grid-20);
          
          @include bp(mobile-tablet) {
	          margin-right: percentage(1 / $grid-20);
          }
          
        }
        
      }
    }

    ul#footer-social {
      margin-left: percentage(14 / $grid-20);
      width: percentage(4 / $grid-20);
      display: flex;
      justify-content: space-between;
      margin-bottom: 54px;

      li {
        display: inline-block;
        margin-right: percentage(1 / $grid-20);
        line-height: 1;
      }

      @include hoverFade();

      @include bp(mobile-tablet) {
        width: 100%;
        margin: 0 auto;
		justify-content: flex-start;
		li {
          margin-right: 20px; //percentage(65 / $content-mobile-max-width);
          width: percentage(6/100);

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }

    #footer-bottom {
      @include clearfix();

      .footer-logo {
        float: left;
        width: percentage(7 / $grid-22);

        svg {
          background: url('../images/hemlocklogofooter.svg');
          width: 100px;
          background-size: 100%;
          background-repeat: no-repeat;
          background-position: center;

          &.svg-hemlocklogofooter {
            float: left;
          }
        }

        p {
          float: none;
          clear: both;
        }

      }

      .footer-logo-50 {
        width: 75px;
        float: left;
        padding-left: 20px;
        clear: right;
        margin-top: -10px;
        margin-bottom: 20px;
      }

      .footer-zero {
        float: right;
        width: percentage(5 / $grid-22);
        padding-top: 13px;
        margin-right: percentage(1.6 / $grid-22);

        @include bp(mobile-tablet) {
          padding-top: percentage(118 / $content-mobile-max-width);
          padding-left: percentage(0 / $content-mobile-max-width);
          padding-bottom: percentage(90 / $content-mobile-max-width);
          margin-bottom: percentage(68 / $content-mobile-max-width);
          border-bottom: solid 1px $brand-primary;
          margin-right: 0px;
        }
      }

      .footer-logo,
      .footer-zero {
        svg {
          margin-bottom: 12px;
        }

        @include bp(mobile-tablet) {
          float: none;
          width: 100%;

          svg {
            margin-bottom: percentage(35 / $content-mobile-max-width);
          }
        }

        p {
          @include remy(12);
          line-height: 1;
          margin: 0;

          @include hoverFade();
        }
      }

    }
  }
}
