/* Home */

body.home {
  main {
    color: $brand-secondary;

    a, a:visited {
      color: $brand-secondary;
    }

    h2 {
      color: $brand-primary;
    }

    p, dd {
      @include linkUnderLine(-3px);
    }

    .home-latest {
      a, a:visited {
        color: $brand-primary;
        &:after {
          border-style: none;
        }
      }
    }
  }
}

#home-hero {
  position: relative;
  width: 100%;
  height: 100vh;

  .pc & {
    @include bp(desktop-all) {
      overflow: hidden;
      max-width: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
    }
  }

  .ie & {
    @include bgFitFullMobile();
    background-size: cover;
    height: 100vh !important;
    background-image: url('/app/uploads/2017/08/hemlock-mobile-home-hero-4.jpg') !important;
  }

  @include bp(mobile-tablet) {
    @include bgFitFullMobile();
    background-size: cover;
  }

  .mobile &,
  .ie & {
    @include bgFitFullMobile();
    background-size: cover;
  }

  & > h1,
  & > p {
    line-height: 1;
  }

  & > .home-hero-content-wrapper {
    width: 85%;
    @include abs(50%, 50%, auto, auto);
    transform: translate(50%, -50%);
    text-align: center;

    .show-for-small {
      text-align: center;
      display: none;

      img {
        margin: 0 auto;
      }

      @include bp(mobile-tablet) {
        display: block;
      }
    }

    h1 {
      margin-bottom: 0;
      @include remy(100);

      @include bp(mobile-tablet) {
        @include remy(48);
        margin-bottom: .5em;
      }

      &.anniversary {
        text-transform: uppercase;
        @include remy(14);
        letter-spacing: 1.2px;
        @include fontStyle();
        margin-top: 40px;
        line-height: 28px;
      }
    }

    p {
      @include remy(20);

      @include bp(mobile-tablet) {
        @include remy(14);
      }
    }
  }

  & > h1 {
    // @include abs(50%, 50%, auto, auto);
    // transform: translate(50%, calc(-50% - 50px));
    @include remy(100);
    width: 100%;
    text-align: center;

    @include bp(mobile-tablet) {
      @include remy(48);
      padding:10px;
    }
  }

  & > p {
    // @include abs(66%, 50%, auto, auto);
    // transform: translate(50%, calc(-50% - 90px));
    //transform: translate(50%, -50%);
    width: 100%;
    text-align: center;
    @include remy(20);

    @include bp(mobile-tablet) {
      @include remy(14);

      @include orientation(landscape) {
        // display: none;
      }
    }
  }
}

#home-hero-vimeo {
  background: #939290;
  @include bp(desktop-all) {
    height: 0;
    height: auto;
    z-index: 9;

    #vimeo-container {
      @include abs-center();

      iframe,
      object,
      embed {
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
      }
    }

    .vimeo-overlay {
      @include abs(0, 0, 0, 0);
      width: 100%;
      height: 100%;
    }
  }

  @include bp(mobile-tablet) {
    @include bgFitFullMobile();
    iframe {
      display: none;
    }
  }

  .mobile &,
  .ie & {
    @include bgFitFullMobile();
    iframe {
      display: none;
    }
  }

  // /* iPAD */
  // @media only screen
  //   and (min-device-width: 768px)
  //   and (max-device-width: 1024px)
  //   and (-webkit-min-device-pixel-ratio: 2) {
  //   iframe {
  //     display: none;
  //   }
  // }

  // ----------- iPad Pro 12.9" -----------

  // /* Portrait and Landscape */
  // @media only screen
  //   and (min-device-width: 1024px)
  //   and (max-device-width: 1366px)
  //   and (-webkit-min-device-pixel-ratio: 2) {
  //     iframe {
  //     display: none;
  //   }
  // }

}

.home-intro {
  position: relative;
  margin: -100px auto 0;
  background: $brand-primary-transparent;
  margin-bottom: 140px;
  z-index: 10;

  @include bp(mobile-tablet) {
    // margin-top: percentage(-169 / $content-mobile-max-width);
    margin-bottom: 80px;
  }

  .home-intro-inner {
    padding: 30px 0 260px;

    @include bp(mobile-tablet) {
      padding-top: 30px;
      padding-bottom: percentage(65 / $content-mobile-max-width);
    }

    .intro-header {
      display: table;
      margin-bottom: 85px;
      width: 100%;

      @include bp(mobile-tablet) {
        margin-bottom: percentage(95 / $content-mobile-max-width);
      }

      .intro-social,
      .intro-down,
      .intro-call,
      .intro-email {
        display: table-cell;
        vertical-align: top;
      }

      .intro-social,
      .intro-down {
        @include bp(mobile-tablet) {
          display: none;
        }
      }

      .intro-social {
        width: percentage(6 / 20);

        @include bp(tablet) {
          width: percentage(5 / 20);
        }

        li {
          display: inline;
          margin-right: percentage(0.5 / 20);

          @include hoverFade();
        }
      }

      .intro-down {
        width: percentage(8 / 20);
        text-align: center;

        @include bp(tablet) {
          width: percentage(7 / 20);
        }
      }

      .intro-call,
      .intro-email {
        width: percentage(3 / 20);

        @include bp(tablet) {
          width: percentage(4 / 20)
        }

        @include bp(mobile-tablet) {
          width: 50%;
          @include remy(14);
        }

      }
    }

    .intro-content {
      dl {
        margin-bottom: 50px;
        width: percentage(12 / 20);

        @include bp(mobile-tablet) {
          width: 100%;
        }

        dt {
          margin-bottom: 16px;

          @include bp(mobile-tablet) {
            @include remy(14);
            margin-bottom: percentage(55 / $content-mobile-max-width)
          }
        }

        dd {
          @include fontStyle2();
          @include remy(38);
          line-height: percentage(44/38);

          @include bp(mobile-tablet) {
            @include remy(21);
            line-height: 26px;
            margin-bottom: percentage(55 / $content-mobile-max-width)
          }
        }
      }

      p {
        margin-left: percentage(14 / 20);
        width: percentage(6 / 20);
        @include linkUnderLine(-3px);

        @include bp(mobile-tablet) {
          text-align: right;
        }
      }
    }
  }
}

.home-category-title {
  margin-bottom: 70px;
  margin-top: 150px;
  // margin-bottom: 505px;

  // &:last-child {
  //   margin-bottom: 70px;
  // }

  @include bp(mobile-tablet) {
    margin-bottom: 45px;
    margin-top: 80px;
  }

  h2, p {
    margin-left: percentage(13 / 20 );
    width: percentage(6 / 20 );

    @include bp(mobile-tablet) {
      margin: 0 auto;
      width: $content-mobile-inner-width;
    }
  }

  h2 {
    @include fontStyle2();
    @include remy(38);
    line-height: 1;
    margin-bottom: 30px;

    @include bp(mobile-tablet) {
      @include remy(37);
      margin-bottom: 20px;
    }
  }

  p {
    color: $brand-primary;
  }
}

@keyframes moveUp {
  from {transform: translateY(-170px)}
  to {transform: translateY(-270px)}
}

.new-parallax {
  background-position: 0,0;
  height: 800px;
  background-attachment: fixed;


  @media screen and (max-width: 1024px) {
     background-attachment: scroll;
     background-size: cover;
  }


  @include bp(mobile) {
    height: 400px;
    background-position: center;
  }

  @media screen and (min-width: 1800px) {
    height: 1000px;
  }

  @include bp(mobile-tablet) {
    background-attachment: scroll;
    background-size: cover;
    height: 60vh;
  }


  .mobile &,
  .ie & {
    background-attachment: scroll;
    background-size: cover;
    height: 60vh;
  }
}

.home-category {
  position: relative;
  // margin-bottom: 155px;
  // margin-top: -370px;
  // transform: translateY(-170px);
  // transition: transform 5s ease-out;
  // animation-name: moveUp;
  // animation-duration: 3s;
  // animation-timing-function: ease-out;
  // animation-fill-mode: forwards;

  .mobile &,
  .ie & {
    margin-top: -350px;
  }
  &.go {
    transform: translateY(-270px);
  }

  &:last-of-type {
    margin-bottom: 70px;
  }

  @include bp(mobile-tablet) {
    padding-top: 205px;
    // margin-bottom: 78px;

    &:last-of-type {
      margin-bottom: 78px;
    }
  }

  .home-section-img {
    display: none;

    @include bp(mobile-tablet) {
      display: none;
      @include bgFitFull(305px);
      @include abs(0, 0, 0, 0);
    }
  }


  .home-category-menu {
    @include bp(mobile-tablet) {
      top: 276px;
    }

    .home-category-menu-inner {
      margin: 0 auto;
      position: relative;
      background: $brand-primary-transparent;
      padding-bottom: 150px;
      // margin-top: -100px;

      @include bp(mobile-tablet) {
        display: block;
        padding-bottom: 45px;
      }

      ul, img.home-hover-img {
        vertical-align: bottom;
      }

      ul {
        display: inline-block;
        padding-top: 140px;
        width: percentage(10 / 22 );

        @include bp(desktop) {
          width: percentage(13 / 22 );
        }

        @include bp(mobile-tablet) {
          display: block;
          margin: 0 auto;
          margin-bottom: percentage(55 / $content-mobile-max-width);
          width: $content-mobile-inner-width;
          padding-top: percentage(75 / $content-mobile-max-width);
        }

        li {
          @include fontStyle2();
          @include remy(48);

          @include bp(mobile-tablet) {
            @include remy(28);
          }

          @include bp(mobile) {
            @include remy(24);
          }

          span {
            @include fontStyle();
            @include remy(20);
            vertical-align: middle;
            padding-bottom: 10px;

            @include bp(mobile-tablet) {
              @include remy(18);
            }
          }

          a {
            display: inline-block;
          }

          @include hoverMidLine();
        }
      }

      img.home-hoverimg {
        position: absolute;
        width: percentage(8 / 22 );
        display: inline;
        opacity: 0;

        &.active {
          opacity: 1;
          animation: alpha0to1 0.45s $ease-in-sine;
        }

        @include bp(desktop) {
          display: none;
          &.active {
            display: none;
          }
        }

        @include bp(mobile-tablet) {
          display: none;
          &.active {
            display: none;
          }
        }
      }

      p {
        margin-bottom: 0;
        width: percentage(3 / 20 );

        @include linkUnderLine(-6px);

        @include bp(mobile-tablet) {
          margin-left: percentage(50 / $content-mobile-max-width);
          width: $content-mobile-inner-width;
          text-align: right;
          @include remy(14);
        }
      }

      &#home-service-inner {
        img.home-hoverimg {
          top: -6em;
        }

        ul {
          margin-left: percentage(11 / 22 );

          @include bp(desktop) {
            margin-left: percentage(1 / 22 );
          }

          @include bp(mobile-tablet) {
            margin: 0 auto 15px;
          }
        }

        li {
          line-height: 58px;

          @include bp(mobile-tablet) {
            line-height: 40px;
          }
        }

        p {
          margin-top: 8.625rem;
          margin-left: percentage(4 / 22 );

          @include bp(mobile-tablet) {
            @include remy(14);
            margin: percentage(65 / $content-mobile-max-width) auto 0;
          }
        }
      }

      &#home-portfolio-inner {
        img.home-hoverimg {
          top: -3em;
          right: 0;
        }

        ul {
          margin-left: percentage(3 / 22 );

          @include bp(mobile-tablet) {
            margin: 0 auto 15px;
          }
        }

        li {
          line-height: 58px;

          @include bp(mobile-tablet) {
            line-height: 40px;
          }
        }

        p {
          margin-top: 5.625rem;
          margin-left: percentage(15 / 22 );

          @include bp(mobile-tablet) {
            margin: percentage(65 / $content-mobile-max-width) auto 0;
          }
        }
      }
    }
  }
}

.home-latest,
.home-partner {
  @include bp(mobile-tablet) {
    margin: 0 auto;
    width: 100%;
  }

  h2 {
    @include remy(38);
    line-height: 1;
    margin-bottom: 45px;

    @include bp(mobile-tablet) {
      margin-top: 80px;
      @include remy(37);
    }
  }
}

.home-latest {
  @include bp(desktop-all) {
    @include spacer(140px);
  }

  @include bp(mobile) {
    margin-bottom: 0; //percentage(90 / $content-mobile-max-width);
  }

  .grid-20 {
    color: $brand-primary;
    display: table;
    border: none;
    padding-bottom: 100px;

    @include bp(mobile-tablet) {
      padding-bottom: 0;
    }

    .home-latest-inner {
      display: table;
      width: 100%;

      ul {
        width: percentage(6 / 20);
        margin-right: percentage(.7 / 20 );
        display: inline-block;
        vertical-align: top;
        // float: left;

        &:last-child {
          margin-right: 0;
        }

        @include bp(mobile) {
          margin-right: 0;
          padding-bottom: 20px;

          &:nth-child(2) {
            display: none;
          }

          &:last-child {
            display: none;
          }

          &.feed-show {
            &:nth-child(2) {
              display: block;
            }

            &:last-child {
              display: block;
            }
          }
        }

        li {
          margin-bottom: 30px;
          position: relative;

          @include bp(mobile) {
            display: block;
            margin-bottom: 30px;
          }

          dl {
            dt {
              margin-bottom: 20px;
              @include hoverFade();
              display: none;

              svg {
                @include abs(50%, 0, 0, 0);
                transform: translate(0, -50%);
                position: absolute;
              }

              @include bp(mobile) {
                @include remy(14);
                margin-bottom: percentage(35 / $content-mobile-max-width);
              }
            }

            dd {
              .social-feed-date {
                display: block;
                margin-bottom: 10px;
                @include remy(14);
              }

              h3 {
                @include fontStyle2();
                @include remy(26);
                line-height: percentage(30/26);
                margin-bottom: 12px;
              }
            }
          }

          &:first-child {
            dl {
              dt {
                display: block;
                margin-bottom: 40px;
              }
            }
          }

          &.News,
          &.Community,
          &.Twitter,
          &.blog-feed-item {
            dd {
              @include hoverFade();
            }
          }

          &.Community,
          &.blog-feed-item {
            dt {
              @include strikeLineLeft(18px);
              padding-left: 35px;
            }
          }

          // &.News {
          //   dt {
          //     @include strikeLineLeft(15px);
          //     padding-left: 32px;
          //   }
          // }

          &.Twitter {
            dt {
              @include strikeLineLeft(23px);
              padding-left: 38px;
            }
          }

          &.Instagram,
          &.Instagram-test {
            dt {
              @include strikeLineLeft(23px);
              padding-left: 38px;
            }

            dd {
              img {
                width: 100%;
              }
            }
          }

          &.Instagram-test {
            @include bp(desktop-all) {
              display: none;
            }
          }

          &.Vimeo {
            dt {
              @include strikeLineLeft(22px);
              padding-left: 37px;
            }

            dd {
              a {
                display: block;
                position: relative;
              }

              svg {
                @include abs-center();
              }

              &:hover {
                svg {
                  opacity: 0.7
                }
              }
            }
          }

        }

        @include bp(mobile) {
          width: 100%;
        }

      }
    }

    .latestLink {
      display: none;

      @include bp(mobile-tablet) {
        display: block;
        @include linkPrimaryColor();
        @include boxLinkWhite(100%);

        &.latestLink__less {
          display: none;
        }
      }

    }

  }
}

.home-partner {
  .grid-20 {
    ul {
      width: 100%;
      display: table;

      li {
        display: inline-block;
        margin-right: 45px;

        @include bp(mobile-tablet) {
          margin-bottom: 45px;
          // margin-right: 25px;
        }

        @media screen and (max-width: 620px) {
          margin-right: 20px;
          max-width: 35px;

          img {

          }
        }
      }
    }
  }
}

.parallax-wrapper {
  @include bp(mobile-tablet) {
    display: none;
  }
}
