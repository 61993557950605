// button,
input[type=button],
input[type=submit] {
  outline: none;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  color: #64a900;
  border: 1px solid #64a900;
  width: 50%;
  text-align: center;
  padding: 1em 0;
  background: transparent;
  border-radius: 0;

  &:hover {
    background-color: $brand-primary;
    color: $brand-secondary!important;
    transition: background-color 0.25s ease-out;
    transition: color 0.25s ease-out;
  }
}

#nf-field-4-container input {
  &:hover {
    background-color: $brand-primary;
    color: $brand-secondary!important;
    transition: background-color 0.25s ease-out;
    transition: color 0.25s ease-out;
  }
}

button {
  outline: none;
  -webkit-tap-highlight-color: transparent;
}

.anchor--button {
  display: inline-block;
  padding: 0 20px 0 40px;
  height: 50px;
  line-height: 46px;
  border: solid #fff 1px;

  &::before {
    content: '';
    display: block;
    width: 11px;
    height: 11px;
    background: url(../images/icon-downarrow.svg) center center no-repeat;
    background-size: contain;
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translateY(-50%);
  }

  &::after {
    display: none;
  }

  &:hover {
    background: #fff;
    color: $brand-primary !important;

    &::before {
      content: '';
      display: block;
      width: 11px;
      height: 11px;
      background: url(../images/icon-downarrow-g.svg) center center no-repeat;
      background-size: contain;
      position: absolute;
      top: 50%;
      left: 20px;
      transform: translateY(-50%);
    }

  }
}