header {
  width:100%;
  position:fixed;
  top:0px;
  z-index: 999;
  //display: none;

  .container {
    padding: 20px 0;
    //@include linkPrimaryColor();

    @include bp(mobile) {
      padding: percentage( 43 / $content-mobile-max-width) 0;
    }

    & > .grid-22 {
      display: flex;
      justify-content: space-between;

      @include bp(mobile-tablet) {
        position: relative;
      }

    }
  }
}

.brand {
  margin-right: 20px;

  &#normalLogo {
    &.hide {
      display: none;
    }
  }
}

.nav-wrap {
  @include bp(mobile-tablet) {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: none;
    z-index: 2;
    background: $brand-primary-bg;
    z-index: -1;
    padding: 85px 0 0;

    .inner {
      padding: 25px 0;
      width: 100%;
      height: 100%;
      overflow-y: auto;
      -webkit-overflow-scrolling:touch;
    }

    &.show {
      display: block;
    }
  }

  @include bp(desktop-all) {
    .grid-22, .grid-20 {
      width: auto;
      margin: 0;
    }
  }
}

.nav-primary {
  @include bp(desktop-all) {
    padding-top: 12px;
  }

  .m-hide {
    @include bp(mobile-tablet) {
      display: none;
    }
  }

  .nav {
    @include bp(mobile-tablet) {
      border-top: 1px solid $brand-secondary;
    }

    @include bp(desktop-all) {
      display: flex;
    }

    & > li {
      @include bp(mobile-tablet) {
        border-bottom: 1px solid $brand-secondary;
      }

      @include bp(desktop-all) {
        position: relative;
        margin-right: 15px;

        &:last-child {
          margin-right: 0;
        }

        @media (hover: hover) {
          &:hover {
            & > a {
              background-size: 100% 1px;
            }

            & > .sub-menu {
              opacity: 1;
              visibility: visible;
              transform: translateY(0);
            }
          }
        }
      }

      @include bp(desktop-limit) {
        margin-right: 25px;
      }

      & > a {
        @include bp(mobile-tablet) {
          display: block;
          @include remy(21);
          @include fontStyle2();
          line-height: 1.2;
          padding: 13px 0;
          color: $brand-secondary;
        }

        @include bp(desktop-all) {
          display: inline-block;
          background-position: 0% calc(100% - 10px);
          background-repeat: no-repeat;
          background-size: 0% 1px;
          transition: background-size 0.25s ease-out;
          @include remy(16);
          padding-bottom: 16px;
          line-height: 1.24;
          color: $brand-primary;
          background-image: linear-gradient($brand-primary, $brand-primary);

          .home header:not(.bg-on) & {
            color: $brand-secondary;
            background-image: linear-gradient($brand-secondary, $brand-secondary);
          }
        }
      }
    }

    .menu-item-has-children {
      @include bp(mobile-tablet) {
        & > a {
          padding-right: 35px;
          background-image: url('../../assets/images/down-chevron.svg');
          background-position: right center;
          background-size: 18px;
          background-repeat: no-repeat;

          &.active {
            background-image: url('../../assets/images/up-chevron.svg');
          }
        }
      }
    }

    .sub-menu {
      @include bp(mobile-tablet) {
        padding: 15px 0 28px;
        display: none;
      }

      @include bp(desktop-all) {
        position: absolute;
        left: -23px;
        top: 100%;
        transform: translateY(10px);
        padding: 18px 23px 10px;
        background-color: #76B726;
        width: 210px;
        transition: all 0.25s ease-out;
        opacity: 0;
        visibility: hidden;
        display: block !important;
      }

      li {
        margin-bottom: 9px;

        @include bp(desktop-all) {
          margin-bottom: 8px;
        }

        a {
          display: inline;
          background-position: 0% 100%;
          background-repeat: no-repeat;
          background-size: 0% 1px;
          transition: background-size 0.25s ease-out;
          @include remy(14);
          padding-bottom: 4px;
          line-height: 1.24;
          color: $brand-secondary;
          background-image: linear-gradient($brand-secondary, $brand-secondary);

          @include bp(desktop-all) {
            @media (hover: hover) {
              &:hover {
                background-size: 100% 1px;
              }
            }
          }
        }
      }
    }
  }
}

.nav-util {
  padding-top: 12px;

  @include bp(mobile-tablet) {
    display: none;
  }

  .nav {
    display: flex;
    margin: 0 -12px;

    & > li {
      position: relative;
      padding: 0 12px;
      
      &::after {
        content: "";
        width: 1px;
        height: 15px;
        background-color: $brand-primary;
        position: absolute;
        right: 0;
        top: 2px;

        @include bp(desktop-all) {
          .home header:not(.bg-on) & {
            background-color: $brand-secondary;
          }
        }
      }

      &:last-child::after {
        display: none;
      }

      @media (hover: hover) {
        &:hover {
          & > a {
            background-size: 100% 1px;
          }

          & > .sub-menu {
            opacity: 1;
            visibility: visible;
            transform: translateY(0);
          }
        }
      }

      & > a {
        display: inline-block;
        background-position: 0% calc(100% - 10px);
        background-repeat: no-repeat;
        background-size: 0% 1px;
        transition: background-size 0.25s ease-out;
        @include remy(14);
        padding-bottom: 16px;
        line-height: 1.24;
        color: $brand-primary;
        background-image: linear-gradient($brand-primary, $brand-primary);

        @include bp(desktop-all) {
          display: inline-block;
          background-position: 0% calc(100% - 10px);
          background-repeat: no-repeat;
          background-size: 0% 1px;
          transition: background-size 0.25s ease-out;
          @include remy(14);
          padding-bottom: 16px;
          line-height: 1.24;
          color: $brand-primary;
          background-image: linear-gradient($brand-primary, $brand-primary);

          .home header:not(.bg-on) & {
            color: $brand-secondary;
            background-image: linear-gradient($brand-secondary, $brand-secondary);
          }
        }
      }
    }

    .sub-menu {
      position: absolute;
      left: -11px;
      top: 100%;
      transform: translateY(10px);
      padding: 18px 23px 10px;
      background-color: #76B726;
      width: 210px;
      transition: all 0.25s ease-out;

      @include bp(desktop-all) {
        opacity: 0;
        visibility: hidden;
      }

      li {
        margin-bottom: 8px;

        a {
          display: inline;
          background-position: 0% 100%;
          background-repeat: no-repeat;
          background-size: 0% 1px;
          transition: background-size 0.25s ease-out;
          @include remy(14);
          padding-bottom: 4px;
          line-height: 1.24;
          color: $brand-secondary;
          background-image: linear-gradient($brand-secondary, $brand-secondary);

          @include bp(desktop-all) {
            @media (hover: hover) {
              &:hover {
                background-size: 100% 1px;
              }
            }
          }
        }
      }
    }
  }
}

.nav-mobile-sec {
  margin-top: 27px;

  @include bp(desktop-all) {
    display: none;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
    
    li {
      width: 50%;
      padding: 0 15px;
      margin-bottom: 5px;

      &:nth-child(even) {
        text-align: right;
      }

      a {
        @include remy(14);
        line-height: 1.375;
        color: $brand-secondary;
      }

      &.btn {
        width: 100%;
        text-align: center;
        margin-top: 40px;

        a {
          @include fontStyle2();
          @include remy(21);
          border: 1px solid $brand-secondary;
          width: 100%;
          display: block;
          padding: 13px 30px;
        }
      }

      &.call {
        width: 100%;
        text-align: center;
        margin-top: 15px;
      }
    }
  }
}

.alert {
  background: $brand-primary;
  color: #fff;
  text-align:center;
  padding: 0;
  z-index: 99;

  a {
    color: #fff;
    text-decoration: underline;
  }
}

.alert-ie {
  display: none;
  position:fixed;
  width:100%;
  z-index: 9999;

  .ie & {
    display: block;
  }
}


/*
 * Made by Erik Terwan
 * 24th of November 2015
 * All rights reserved
 *
 *
 * If you are thinking of using this in
 * production code, beware of the browser
 * prefixes.
 */

//a
//{
//  text-decoration: none;
//  color: #232323;
//
//  transition: color 0.3s ease;
//}

#headerToggle {
  display: none;
  top: 0;
  right: 0;
  position: absolute;
  z-index: 20;

  @include bp(mobile-tablet) {
    display: block;
  }

  #menuToggle
  {
    display: block;
    position: relative;
    top: 5px;
    right: 0;
    padding-top: 4px;

    z-index: 1;

    -webkit-user-select: none;
    user-select: none;

    input
    {
      display: block;
      width: 45px;
      height: 30px;
      position: absolute;
      top: 7px;
      left: -5px;

      cursor: pointer;

      opacity: 0; /* hide this */
      z-index: 2; /* and place it over the hamburger */

      -webkit-touch-callout: none;
    }

    /*
     * Just a quick hamburger
     */
    span
    {
      display: block;
      width: 24px;
      height: 2px;
      margin-bottom: 6px;
      position: relative;

      background-color: $brand-primary;

      z-index: 1;

      transform-origin: 4px 0px;

      transition: transform 0.3s cubic-bezier(0.77,0.2,0.05,1.0),
      background 0.3s cubic-bezier(0.77,0.2,0.05,1.0),
      opacity 0.35s ease;

      &:first-child
      {
        transform-origin: 0% 0%;
      }

      &:nth-last-child(2)
      {
        transform-origin: 0% 100%;
        margin-bottom: 0;
      }

    }

    input:checked ~ span
    {
      opacity: 1;
      transform: rotate(45deg) translate(-2px, 3px);
      background: $brand-secondary;
    }

    /*
   * But let's hide the middle one.
   */
    input:checked ~ span:nth-last-child(3)
    {
      opacity: 0;
      transform: rotate(0deg) scale(0.2, 0.2);
    }

    /*
     * Ohyeah and the last one should go the other direction
     */
    input:checked ~ span:nth-last-child(2)
    {
      opacity: 1;
      transform: rotate(-45deg) translate(0, -3px);
    }

  }
}

/*
 * Make this absolute positioned
 * at the top left of the screen
 */
#menu
{
  position: absolute;
  //width: 300px;
  //margin: -100px 0 0 -50px;
  //padding: 50px;
  //padding-top: 125px;
  //display: none;
  background: $brand-primary;

  color: $brand-secondary;

  @include linkSecondaryColor();

  list-style-type: none;
  -webkit-font-smoothing: antialiased;
  /* to stop flickering of text in safari */

  //transform-origin: 0% 0%;
  //transform: translate(-100%, 0);

  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);

  li {
    padding: 10px 0;
    font-size: 22px;
  }
}

/*
 * And let's fade it in from the left
 */
#menuToggle input:checked ~ ul
{
  transform: scale(1.0, 1.0);
  opacity: 1;
}

body.home {
  #menuToggle {
    span {
      background: $brand-secondary!important;
    }
  }
}


/* Header Background */

body {
  header {
    background-color: $brand-secondary;
    // transition: background-color .2s ease-in;

    .container {
      //@include linkPrimaryColor();

      svg.svg-hemlocklogonavwhite {
        background: url('../images/hemlocklogonavwhite.svg');
        width: 126px;
        background-size: 100%;
        background-repeat: no-repeat;
        display: none;

        @include bp(mobile-tablet) {
          width: 100px;
        }
      }

      svg.svg-hemlocklogofooter {
        display: inline;
        background: url('../images/hemlocklogofooter.svg');
        width: 126px;
        background-size: 100%;
        background-repeat: no-repeat;
        height: 40px;

        @include bp(mobile-tablet) {
          width: 100px;
          height: 30px;
        }
      }
    }

    &.bg-on,
    .ie & {
      background-color: $brand-secondary;
      // transition: background-color .2s ease-in;

      .container {
        //@include linkPrimaryColor();

        svg.svg-hemlocklogonavwhite {
          display: none;
        }
        svg.svg-hemlocklogofooter {
          display: inline;
          background: url('../images/hemlocklogofooter.svg');
          width: 126px;
          background-size: 100%;
          background-repeat: no-repeat;
          height: 40px;

          @include bp(mobile-tablet) {
            width: 100px;
            height: 30px;
          }
        }
      }

      #menuToggle {
        span {
          background: $brand-primary!important;
        }
      }
    }

    @include bp(mobile-tablet) {
      &.bg-menu-on {
        background-color: transparent !important; //$brand-primary-bg!important;

        .container {
          @include linkSecondaryColor();

          svg.svg-hemlocklogonavwhite { display: inline }
          svg.svg-hemlocklogofooter { display: none }
        }

        #menuToggle {
          span {
            background: $brand-secondary!important;
          }
        }
      }
    }
  }
}

body.home {
  header {
    background: none;
    // transition: background-color .2s ease-in;

    .container {
      //@include linkSecondaryColor();

      svg.svg-hemlocklogonavwhite { display: inline }
      svg.svg-hemlocklogofooter { display: none }
    }

    &.bg-on {
      background-color: $brand-secondary;

      .container {
        //@include linkPrimaryColor();

        svg.svg-hemlocklogonavwhite { display: none }
        svg.svg-hemlocklogofooter { display: inline }
      }

      #menuToggle {
        span {
          background: $brand-primary!important;
        }
      }
    }

    @include bp(mobile-tablet) {
      &.bg-menu-on {
        background-color: transparent !important; //$brand-primary-bg!important;

        .container {
          @include linkSecondaryColor();

          svg.svg-hemlocklogonavwhite { display: inline }
          svg.svg-hemlocklogofooter { display: none }
        }

        #menuToggle {
          span {
            background: $brand-secondary!important;
          }
        }
      }
    }
  }
}


.ie .banner {
  background: #fff;

  a, a:visited, a:hover, a:focus {
    color: $brand-primary !important;
  }

  svg.svg-hemlocklogonavwhite {
    display: none !important;
  }
  svg.svg-hemlocklogofooter {
    display: inline !important;
  }
}

#company-menu-list {
  > li.active a {
    .parent-pageid-34 &,
    .parent-pageid-38 & {
      &::after {
        content: "";
        width: 100%;
        position: absolute;
        bottom: -5px;
        left: 0;
        border-width: 0 0 1px;
        border-style: solid;
        -webkit-animation: zeroToHundred .25s ease-out;
        animation: zeroToHundred .25s ease-out;
      }
    }
  }
}
