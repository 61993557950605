.testbg {
  background-color: $brand-primary;
}

.lightbox {
  @include abs(0, 0, 0, 0);
  //transform: translate(50%, -50%);
  width: 100%;
  height:100%;
  z-index: 999999999;
  background-color: rgba(0, 0, 0, 0.9);
  padding: 100px 0 60px;
  visibility: hidden;
  opacity: 0;
  transition: opacity .3s ease-in-out;
  position: fixed;

  &.show {
    visibility: visible;
    overflow: scroll;
    opacity: 1;
  }

  @include bp(mobile-tablet) {
    padding: 30px 0;
  }

  .lightbox-item {
    display: none;
    &.show {
      display: block;
    }
  }

  .lightbox-inner {
    margin: 0 auto;
    position: relative;
    width: percentage(22/24);
    height: 100%;

    @include bp(mobile-tablet) {
      padding-top: 75px;
      width: 100%;
    }

    img {
      @include abs(50%, 50%, auto, auto);
      transform: translate(50%, -50%);
      max-width:960px;
      width: auto;
      max-height: 100%;

      @include bp(desktop) {
        // width: percentage(18/24);
        width: auto;
      }

      @include bp(mobile-tablet) {
        position: static;
        margin: 0 auto;
        width: percentage(22/24);
        transform: none;
      }
    }

    .lightbox-button-box {
      position: absolute;
      width: 100%;
      //height: 100%;
      top: 0;
      left: 0;
      z-index: 3;

      @include bp(mobile-tablet) {
        top: 14px;
        width: 100%;
      }
    }

    .grid-22 {
      position: relative;
      height: 100%;
      @include clearfix();

      button {
        position: absolute;
        display: block;
        width: percentage(1/22);

        @include hoverFadeBtn();

        &.left {
          //@include abs(50%, 0, auto, 0);
          position: fixed;
          top: 50%;
          left: 30px;
          transform: translateX(0) translateY(-50%) translateZ(0);
        }

        &.right {
          position: fixed;
          top: 50%;
          right: 30px;
          transform: translateX(0) translateY(-50%) translateZ(0);
        }

        &.close {
          @include abs(0, auto, auto, 100%);
          transform: translate(-100%, -100%);
          margin-top: 0;
          text-align: right;

          @include bp(desktop) {
            // margin-top: percentage(-313/$content-max-width);
          }

          @include bp(mobile-tablet) {
            position: static;
            display: inline-block;
            float: right;
            margin-top: 11px;
            margin-right: 4%;
            width: 45px;
            transform: none;
          }
        }

        &.right, &.left {
          height: 46px;

          @include bp(mobile-tablet) {
            position: static;
            display: inline-block;
            float: left;
            margin-left: 4%;
            width: 45px;
            transform: none;
          }
        }

        &.right {

          @include bp(mobile-tablet) {
            margin-left: 20px;
          }
        }
      }
    }

    .lightbox-inner-box {
      position: absolute;
      width: 100%;
      height: 80%;
      top: 0;
      left: 0;

      @include bp(desktop) {
        position: absolute;
      }

      @include bp(mobile-tablet) {
        position: relative;
      }

      .grid-22 {
        position: relative;
        margin: 0 auto;
        height: 100%;

        .lightbox-inner-text {
          @include abs-center();
          color: $brand-secondary;
          width: percentage(18/22);
          top: auto;
          bottom: -80px;
          // margin-top: 395px;

          @include bp(desktop) {
            // margin-top: percentage(470/$content-max-width);
          }

          @include bp(mobile-tablet) {
            position: static;
            transform: none;
            margin: 2em auto 0;
          }

          h2, p {
            width: percentage(6/18);

            @include bp(desktop) {
              width: percentage(8/18);
            }

            @include bp(mobile-tablet) {
              width: 100%;
            }
          }


          h2 {
            line-height: 1;
            margin: 0 0 22px;
            padding: 0;
          }

          p.subText {
            line-height: 1;
            margin-bottom: 7px;
          }
        }
      }
    }
  }
}

.lightbox-item {

      .lightbox-inner-text {
      // @include abs-center();
      position: absolute;
      color: $brand-secondary;
      width: percentage(18/22);
      max-width: 1124px;
      margin: 0 auto;
      top: 85%;
      left: 25%;
      bottom: auto;
      // margin-top: 395px;

      @include bp(mobile-tablet) {
        position: static;
        transform: none;
        margin: 2em auto 0;
      }

      h2, p {
        width: percentage(6/18);

        @include bp(desktop) {
          width: percentage(8/18);
        }

        @include bp(mobile-tablet) {
          width: 100%;
        }
      }


      h2 {
        line-height: 1;
        margin: 0 0 22px;
        padding: 0;
        @include remy(26);
      }

      p.subText {
        line-height: 1;
        margin-bottom: 7px;
      }
    }
  .grid-22 {
    position: relative;
    margin: 0 auto;
    height: 100%;


  }
}



#indexMenu {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
  z-index: 2;
  background: $brand-primary-bg;
  overflow-y: scroll;
  -webkit-overflow-scrolling:touch;
  z-index: 99;
  // opacity: 0;
  // transition: opacity 1s ease-in;

  .is--cookie__notaccepted & {
      @include bp(mobile-tablet) {
          top: 46px;
      }
  }

  .container {
    padding: 108px 0 0;

    .grid-22 {
      color: $brand-secondary;
      @include linkSecondaryColor();

      .grid-20
        dl {
          &:first-child {
            //padding-top: percentage( 115 / $content-mobile-max-width);
          }

          &#index-accordion-menu {
            border-bottom: solid 1px $brand-secondary;
            margin-bottom: 2em;
          }

          dt {
            border-top: solid 1px $brand-secondary;
            @include fontStyle2();
            @include fz_vw(21);
            line-height: 1;

            &#index-company, &#index-sustainability {
              padding: 0.7em 0 0.8em;
              background-image: url('../../assets/images/down-chevron.svg');
              background-position: right center;
              background-size: 18px;
              background-repeat: no-repeat;
              cursor: pointer;

              &.active {
                background-image: url('../../assets/images/up-chevron.svg');
              }
            }

            a {
              display: block;
              padding: 0.7em 0;
            }
          }

          dd {
            @include fz_vw(14);
            display: none;
            margin-top: 9px;

            li:last-child {
              padding-bottom: 30px;
            }

          // .parent-pageid-34 & {
          //   display: block;
          // }

            a {
              display: block;
              padding: 0.3em 0;
            }
          }
        }

        .index-inner {
          @include clearfix();
          width: 100%;
          margin-bottom: 3em;

          ul {
            float: left;
            width: 50%;

            &:last-child {
              float: right;
              text-align: left;
              max-width: 90px;
            }

            li {
              margin-bottom: 0.3em;
            }
          }
        }

      .index-quote {
        @include fz_vw(21);
        @include fontStyle2();
        @include boxLinkWhite(100%);
        margin-bottom: 1em;
        a {
          color: $brand-secondary;
          border: solid 1px $brand-secondary;
          padding: .5em;
        }
      }

      p {
        text-align: center;
      }
    }
  }

  &.show {
    display: block;
    opacity: 1;
    // animation: fadeIn 1s ease-in;
  }
}

.dropbtn {
  background-color: #4CAF50;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;

  &:hover, &:focus {
    background-color: #3e8e41;
  }
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;

  a  {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;

    &:hover {
      background-color: #f1f1f1
    }
  }
}

.show {display:block;}
