/* Employment */

body.employment,
body.careers {
  main {
    color: $brand-primary;
  }
}

#employment-intro {
  position: relative;
  margin-top: 500px;
  margin-bottom: 150px;
  padding-bottom: 65px;

  @include bp(mobile-tablet) {
    margin-bottom: 57px;
    padding-bottom: 30px;
    margin-top: -150px;
  }

  .grid-20 {
    .top,
    .bottom {
      vertical-align: top;
    }

    .top {
      width: percentage(8 / 20);

      @include bp(mobile-tablet) {
        width: percentage(15 / 20);
        margin-bottom: 42px;
      }
    }

    .bottom {
      margin-left: percentage(14 / 20);
      width: percentage(6 / 20);

      @include bp(mobile-tablet) {
        margin-left: 0;
        width: 100%;
      }

      h3 {
        @include remy(26);
        line-height: 1;
        margin-bottom: 12px;

        @include bp(mobile-tablet) {
          margin-bottom: 12px;
        }
      }

      p {
        margin-bottom: 30px;

        @include linkUnderLine();

        a {
          display: inline-block;
          margin-bottom: 5px;
        }
      }
    }
  }
}
