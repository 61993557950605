%svg-common {
	background: url("svg/sprite.css-ac9bfbdb.svg") no-repeat;
}

.svg-CSBRlogo {
	@extend %svg-common;
	background-position: 0 62.2768478013503%;
	width: 118px;
	height: 38.624px;
}

.svg-aiicon {
	@extend %svg-common;
	background-position: 98.78048780487805% 46.29488513865141%;
	width: 33px;
	height: 32.176px;
}

.svg-blog-facebook {
	@extend %svg-common;
	background-position: 48.55462427745664% 63.30176470588236%;
	width: 15px;
	height: 15px;
}

.svg-blog-linkedin {
	@extend %svg-common;
	background-position: 52.889884393063575% 63.30176470588236%;
	width: 15px;
	height: 15px;
}

.svg-blog-twitter {
	@extend %svg-common;
	background-position: 88.0813953488372% 62.655927593322076%;
	width: 17px;
	height: 13.816px;
}

.svg-certclimatesmartlogowhite {
	@extend %svg-common;
	background-position: 86.66666666666667% 56.303217821782184%;
	width: 76px;
	height: 31.8px;
}

.svg-certg7logowhite {
	@extend %svg-common;
	background-position: 78.16455696202532% 76.01709677419356%;
	width: 45px;
	height: 45px;
}

.svg-certgreenelogowhite {
	@extend %svg-common;
	background-position: 0 99.91199812955435%;
	width: 39px;
	height: 47.052px;
}

.svg-certoffsetlogowhite {
	@extend %svg-common;
	background-position: 26.498422712933753% 98.9315112540193%;
	width: 44px;
	height: 44px;
}

.svg-docicon {
	@extend %svg-common;
	background-position: 97.07602339181287% 54.792689169251155%;
	width: 19px;
	height: 22.89px;
}

.svg-docicongreen {
	@extend %svg-common;
	background-position: 64.91228070175438% 14.586733311252297%;
	width: 19px;
	height: 22.89px;
}

.svg-down {
	@extend %svg-common;
	background-position: 36.17789186255847% 49.648988518316024%;
	width: 12.721px;
	height: 34.925px;
}

.svg-downarrowicon {
	@extend %svg-common;
	background-position: 95.98482745859933% 59.107368008009395%;
	width: 15.112px;
	height: 8.407px;
}

.svg-epnlogo {
	@extend %svg-common;
	background-position: 0 25.32236875669609%;
	width: 211px;
	height: 41.384px;
}

.svg-exlinkicon {
	@extend %svg-common;
	background-position: 50.089695385719324% 47.70673599394788%;
	width: 18.173px;
	height: 21.894px;
}

.svg-facebook {
	@extend %svg-common;
	background-position: 43.31395348837209% 58.292603550295865%;
	width: 17px;
	height: 17px;
}

.svg-footerfacebook {
	@extend %svg-common;
	background-position: 44.05768115942029% 63.48849557522124%;
	width: 16px;
	height: 16px;
}

.svg-footerinstagram {
	@extend %svg-common;
	background-position: 48.25581395348837% 58.292603550295865%;
	width: 17px;
	height: 17px;
}

.svg-footerlinkedin {
	@extend %svg-common;
	background-position: 53.19767441860465% 58.292603550295865%;
	width: 17px;
	height: 17px;
}

.svg-footerpinterest {
	@extend %svg-common;
	background-position: 58.13953488372093% 58.292603550295865%;
	width: 17px;
	height: 17px;
}

.svg-footertwitter {
	@extend %svg-common;
	background-position: 77.84256559766764% 62.80558566975448%;
	width: 18px;
	height: 14.629px;
}

.svg-fsclogowhite {
	@extend %svg-common;
	background-position: 100% 33.80512107071664%;
	width: 37px;
	height: 44.479px;
}

.svg-gdclogo {
	@extend %svg-common;
	background-position: 0 14.950513688589604%;
	width: 222px;
	height: 30.971px;
}

.svg-hemlocklogofooter {
	@extend %svg-common;
	background-position: 0 38.12076936428042%;
	width: 126px;
	height: 38.115px;
}

.svg-hemlocklogonavwhite {
	@extend %svg-common;
	background-position: 0 50.14879214857125%;
	width: 126px;
	height: 38.115px;
}

.svg-inddicon {
	@extend %svg-common;
	background-position: 42.29298780487805% 49.22620375188958%;
	width: 33px;
	height: 32.176px;
}

.svg-inklingsicon {
	@extend %svg-common;
	background-position: 33.9080459770115% 58.49977583335066%;
	width: 13px;
	height: 18.197px;
}

.svg-instagram {
	@extend %svg-common;
	background-position: 63.08139534883721% 58.292603550295865%;
	width: 17px;
	height: 17px;
}

.svg-instagramicon {
	@extend %svg-common;
	background-position: 38.19241982507289% 58.46557863501484%;
	width: 18px;
	height: 18px;
}

.svg-leftarrow {
	@extend %svg-common;
	background-position: 99.60724664002906% 53.53330018857212%;
	width: 8.616px;
	height: 15.077px;
}

.svg-leftbutton {
	@extend %svg-common;
	background-position: 92.40506329113924% 76.01709677419356%;
	width: 45px;
	height: 45px;
}

.svg-linkedin {
	@extend %svg-common;
	background-position: 34.30222586562249% 63.676331360946755%;
	width: 16.999px;
	height: 17px;
}

.svg-magnifyingglassicon {
	@extend %svg-common;
	background-position: 64.76922510221873% 24.835426015980488%;
	width: 35.228px;
	height: 35.235px;
}

.svg-minusgreen {
	@extend %svg-common;
	background-position: 96.95832805864684% 29.224858757062147%;
	width: 13.428px;
	height: 1px;
}

.svg-minuswhite {
	@extend %svg-common;
	background-position: 99.86765332075082% 57.87062146892656%;
	width: 13.428px;
	height: 1px;
}

.svg-newsicon {
	@extend %svg-common;
	background-position: 91.76136363636364% 56.297817969192316%;
	width: 9px;
	height: 31.769px;
}

.svg-partnercanopylogo {
	@extend %svg-common;
	background-position: 51.012145748987855% 36.95436037529789%;
	width: 114px;
	height: 28.113px;
}

.svg-partnerclimatesmartlogo {
	@extend %svg-common;
	background-position: 100% 0;
	width: 114px;
	height: 47.7px;
}

.svg-partnerepnlogo {
	@extend %svg-common;
	background-position: 0 0;
	width: 247px;
	height: 48.444px;
}

.svg-partnerfsclogo {
	@extend %svg-common;
	background-position: 0 83.27667364016737%;
	width: 60px;
	height: 72.024px;
}

.svg-partnergreeenelogo {
	@extend %svg-common;
	background-position: 43.0976430976431% 80.85843006598294%;
	width: 64px;
	height: 63.561px;
}

.svg-partneroffsetterlogo {
	@extend %svg-common;
	background-position: 20.477815699658702% 82.10905923344949%;
	width: 68px;
	height: 68px;
}

.svg-pinterest {
	@extend %svg-common;
	background-position: 39.243895348837206% 63.676331360946755%;
	width: 17px;
	height: 17px;
}

.svg-playbutton {
	@extend %svg-common;
	background-position: 86.97183098591549% 37.75971223021583%;
	width: 77px;
	height: 77px;
}

.svg-plusgreen {
	@extend %svg-common;
	background-position: 92.06725512987236% 62.5847551907065%;
	width: 13.428px;
	height: 13.428px;
}

.svg-pluswhite {
	@extend %svg-common;
	background-position: 95.93062732325964% 62.5847551907065%;
	width: 13.428px;
	height: 13.428px;
}

.svg-postnexticon {
	@extend %svg-common;
	background-position: 98.07944622901867% 62.48158626978745%;
	width: 7.352px;
	height: 12.864px;
}

.svg-postprevicon {
	@extend %svg-common;
	background-position: 55.98759218205672% 62.90656347183576%;
	width: 7.352px;
	height: 12.864px;
}

.svg-readmoreicon {
	@extend %svg-common;
	background-position: 62.745098039215684% 76.42758687790878%;
	width: 55px;
	height: 46.665px;
}

.svg-rightarrow {
	@extend %svg-common;
	background-position: 66.4048310933527% 57.962832759183705%;
	width: 8.616px;
	height: 15.077px;
}

.svg-rightbutton {
	@extend %svg-common;
	background-position: 12.341772151898734% 99.25064516129032%;
	width: 45px;
	height: 45px;
}

.svg-twitter {
	@extend %svg-common;
	background-position: 83.09037900874635% 62.80558566975448%;
	width: 18px;
	height: 14.629px;
}

.svg-twittericon {
	@extend %svg-common;
	background-position: 72.43401759530792% 63.1068706346348%;
	width: 20px;
	height: 16.254px;
}

.svg-vimeo {
	@extend %svg-common;
	background-position: 60.90204081632653% 46.81762473779726%;
	width: 18px;
	height: 15.568px;
}

.svg-vimeofooter {
	@extend %svg-common;
	background-position: 66.14985422740526% 46.81762473779726%;
	width: 18px;
	height: 15.568px;
}

.svg-vimeoicon {
	@extend %svg-common;
	background-position: 55.52456140350878% 46.93723841957427%;
	width: 19px;
	height: 16.433px;
}

.svg-x {
	@extend %svg-common;
	background-position: 100% 24.004374648789423%;
	width: 24px;
	height: 24.002px;
}

.svg-zerologofooter {
	@extend %svg-common;
	background-position: 91.14391143911439% 24.114822661025492%;
	width: 90px;
	height: 25.518px;
}

.svg-zerologoheader {
	@extend %svg-common;
	background-position: 99.19678714859438% 14.756563112923285%;
	width: 112px;
	height: 31.754px;
}

