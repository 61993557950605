// Page header
.page-header-cplp {
  padding: 160px 0 67px;
  @include remy(16);
  line-height: 1.375;

  @include bp(desktop-all) {
    padding: 163px 0 142px;
    @include remy(18);
    line-height: 1.27;
  }

  h1 {
  	@include remy(48);
  	line-height: 1.125;
  	margin: 0 0 13px;

    @include bp(desktop-all) {
      @include remy(72);
    }
  }

  p {
  	margin: 0;

    br {
      @include bp(mobile) {
        display: none;
      }
    }
  }
}

.page-header-cpdp {
  padding: 140px 0 69px;
  text-align: center;
  color: $brand-primary;

  @include bp(desktop-all) {
    padding: 153px 0 133px;
  }

  h1 {
    @include remy(48);
    line-height: 1.125;
    margin: 0;

    @include bp(desktop-all) {
      @include remy(78);
    }
  }

  .subtitle {
    @include remy(14);
    line-height: 1;
  }
}

// Custom Post - Main Content
.tax-portfolio-category,
.single-services {
  .main-content {
    padding-bottom: 45px;

    @include bp(desktop-all) {
      padding-bottom: 35px;
    }
  }

    .featured-text {
      background-color: rgba(118, 183, 38, 0.88);
      color: $brand-secondary;
      padding: 34px 0 75px;
      margin: 11px 0 68px;

      @include bp(desktop-all) {
        padding: 67px 0 147px;
        margin: 0 0 80px;
      }

      .cat {
        @include remy(14);
        line-height: 1.57;
        margin-bottom: 23px;

        @include bp(desktop-all) {
          margin-bottom: 15px;
        }
      }

      p {
        @include remy(21);
        line-height: 1.14;
        @include fontStyle2();
        margin-bottom: 0;
        max-width: 794px;
        width: 92%;

        @include bp(desktop-all) {
          @include remy(38);
          line-height: 1.16;
        }
      }
    }

    .entry-content {
      display: flex;
      justify-content: flex-end;
      color: $brand-primary;

      .inner {
        max-width: 638px;
      }

      h2 {
        @include remy(38);
        line-height: 1.13;
        @include fontStyle2();
        margin: 57px 0 26px;

        @include bp(desktop-all) {
          margin: 54px 0 26px;
        }

        &:first-child {
          margin-top: 0;
        }
      }

      ul {
        list-style: disc;
        margin: 0 0 14px 15px;
      }

      a {
        color: $brand-primary;
        display: inline;
        padding-bottom: 2px;
        background-image: linear-gradient($brand-primary, $brand-primary);
        background-position: 0% 100%;
        background-repeat: no-repeat;
        background-size: 100% 1px;
        transition: all 0.2s ease-out;

        &:visited {
          color: $brand-primary;
        }

        @media (hover: hover) {
          &:hover {
            background-size: 0% 1px;
          }
        }
      }
    }
}

// CTA
.cta {
  margin-top: 133px;
  position: relative;

  @include bp(desktop-all) {
    margin-top: 173px;
  }

  .image {
    img {
      width: 100%;
    }
  }

  .text {
    position: absolute;
    top: -127px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(118, 183, 38, 0.88);
    color: $brand-secondary;
    padding: 34px 0;
    min-height: 250px;

    @include bp(desktop-all) {
      top: -142px;
      padding: 67px 0;
      min-height: 375px;
    }

    p {
      @include remy(21);
      line-height: 1.14;
      @include fontStyle2();
      margin-bottom: 43px;
      max-width: 615px;
      width: 92%;

      @include bp(desktop-all) {
        @include remy(38);
        line-height: 1.16;
        margin-bottom: 48px;
      }

      a {
        color: $brand-secondary;
        display: inline;
        padding-bottom: 2px;
        background-image: linear-gradient($brand-secondary, $brand-secondary);
        background-position: 0% 100%;
        background-repeat: no-repeat;
        background-size: 100% 1px;
        transition: all 0.2s ease-out;

        &:visited {
          color: $brand-secondary;
        }

        @media (hover: hover) {
          &:hover {
            background-size: 0% 1px;
          }
        }
      }
    }

    .link {
      @include remy(14);
      line-height: 1.57;
      color: $brand-secondary;
      display: inline;
      padding-bottom: 2px;
      background-image: linear-gradient($brand-secondary, $brand-secondary);
      background-position: 0% 100%;
      background-repeat: no-repeat;
      background-size: 100% 1px;
      transition: all 0.2s ease-out;

      @media (hover: hover) {
        &:hover {
          background-size: 0% 1px;
        }
      }
    }
  }
}