/* Submit */

// submit, prepare, faq
body.submit-files,
body.page-id-58,
body.page-id-48 {
  main {
    color: $brand-primary;

    a, a:visited {
      color: $brand-primary;
    }
  }
}

// Submit
body.submit-files {
  main {
    #page-intro-box {
      #page-intro {
        p {
          color: $brand-primary;
          margin-bottom: 40px;
        }
        #submitBtn {
          width: percentage(2.5 / 8);

          @include bp(mobile-tablet) {
            width: 100%;
            margin-bottom: 52px;
          }
        }
      }
    }
  }
}

#submit-prepare-menu {
  display: table;
  border: none;
  margin-bottom: 40px;

  li {
    display: inline;
    margin-right: 10px;

    //@include hoverFade();
    @include hoverUnderLine(-5px);

    &.active {
      position: relative;
    }
    &.active a:after {
      content: '';
      width: 100%;
      position: absolute;
      bottom: -5px;
      left: 0;
      border-width: 0 0 1px;
      border-style: solid;
    }
  }
}

#submit-text {
  display: table;
  border-top: solid 1px $brand-primary-light-line;
  padding-bottom: 0;

  @include bp(mobile-tablet) {
    padding-bottom: 74px;
  }

  .submit-text-item {
    display: inline-block;
    vertical-align: top;
    width: percentage(6 / 20);

    @include bp(mobile-tablet) {
      width: 100%;
    }

    &:last-child {
      margin-left: percentage(1 / 20);

      @include bp(mobile-tablet) {
        margin-left: 0;
      }
    }

    h2 {
      @include remy(26);
      line-height: 1;
      margin: 68px 0 35px;

      @include bp(mobile-tablet) {
        margin: 42px 0 0;
      }
    }

    p {
      margin-top: 0;
    }

    ul {
      list-style: none;
      li {
        display: inline-block;
        @include linkUnderLine();
        margin-bottom: 10px;
        padding-right: 60px;
        position: relative;

        svg {
          margin-left: 14px;
          vertical-align: middle;
          position: absolute;
          right: 25px;
          top: 0;
        }
      }
    }
  }
}

#submit-faqs {
  background-color: $brand-primary-transparent;
  padding: 60px 0 135px;
  color: $brand-secondary;

  @include bp(mobile-tablet) {
    padding: 35px 0 85px;
  }

  h2 {
    @include remy(48);
    line-height: 1;
    margin-bottom: 45px;
  }

  @include linkUnderLine;

  a {
    color: #fff;
  }

  dl.accordion-menu {
    dt {
      position: relative;
      padding-left: 25px;
      margin-top: 16px;
      margin-bottom: 0;
      line-height: 30px;

      @include bp(mobile-tablet) {
        @include remy(21);
        line-height: 24px;
      }

      svg {
        position: absolute;
        left: 0px;
        max-width: 20px;
      }

      svg.svg-pluswhite-new {
        background: url('../images/svg/pluswhite.svg');
        background-position: left top;
        background-repeat: no-repeat;
        top: 10px;
        display: block;

        @include bp(mobile-tablet) {
          top: 6px;
        }
      }

      svg.svg-minuswhite-new {
        background: url('../images/svg/minuswhite.svg');
        background-position: left top;
        background-repeat: no-repeat;
        top: 16px;
        display: none;

        @include bp(mobile-tablet) {
          top: 12px;
        }
      }

      &.active {
        svg.svg-pluswhite-new {
          display: none;
        }

        svg.svg-minuswhite-new {
          display: inline-block;
        }
      }
    }

    dd {
      padding-left: 25px;
      padding-top: 9px;

      @include bp(mobile-tablet) {
        padding-top: 20px;
      }

      p {
        margin: 0;
      }
    }
  }
}

// Prepare
body.page-id-58 {
  main {
    #page-intro-box {
      padding-bottom: 52px;
      border-bottom: 1px solid $brand-primary;
      margin-bottom: 120px;
      display: flex;


      @include bp(mobile-tablet) {
        padding-bottom: 49px;
        margin-bottom: 0;

        p {
          margin-bottom: 0;
        }
      }
    }
  }
}

/* Prepare */

.prepare-list {
  margin-bottom: 100px;

  @include bp(mobile-tablet) {
    margin-bottom: 49px;
  }

  .boxLinkListTitle {
    max-width: 460px;
  }
}

#prepare-text {
  padding: 66px 0 18px;
  margin-bottom: 160px;
  background-color: $brand-primary;
  color: $brand-secondary;

  @include bp(mobile-tablet) {
    padding: 30px 0 0px;
    margin-bottom: 36px;
  }

  .prepare-text-item {
    display: table;
    width: 100%;
    padding-bottom: 109px;

    &:first-child {
      border-bottom: solid 1px $brand-secondary;
      margin-bottom: 70px;
    }

    @include bp(mobile-tablet) {
      padding-bottom: 50px;

      &:first-child {
        margin-bottom: 36px;
      }
    }


    .grid-left,
    .grid-right {
      vertical-align: top;
      display: inline-block;
    }

    .grid-left {
      width: percentage(6 / 20);

      @include bp(mobile-tablet) {
        width: 100%;
        margin-right: 0;
      }

      h2 {
        @include remy(56);
        line-height: 1;

        @include bp(mobile-tablet) {
          @include fz_vw(28);
          width: 100%;
          margin-bottom: 0;
        }
      }
    }

    .grid-right {
      width: percentage(13 / 20);
      margin-left: percentage(1 / 20);
      padding-top: 13px;
      @include clearfix();

      @include bp(mobile-tablet) {
        margin-left: 0;
        padding-top: 26px;
        width: 100%;
      }

      .top,
      .bottom {
        @include clearfix();
      }

      .left {
        float: left;
        width: percentage(6 / 13);
        margin-right: percentage(1 / 20);

        @include bp(mobile-tablet) {
          float: none;
          width: 100%;
          margin-right: 0;
        }

        p {
          margin-top: 0;
        }

        ul {
          li {
            @include strikeLineLeft(-23px, 11px, 18px);
            position: relative;
            margin-bottom: 1rem;
            @include breakWord();
            span {
              @include abs(0, auto, 0, -45px);
              position: absolute;
            }
          }

          @include bp(mobile-tablet) {
            li {
              @include strikeLineLeft(19px, 11px, 16px);
              padding-left: 40px;

              span {
                @include abs(0, auto, 0, 0);
              }
            }
          }
        }
      }
    }

    .right {
      float: right;
      width: percentage(6 / 13);

      @include boxLinkWithAdobeIcon();

      @include bp(mobile-tablet) {
        float: none;
        width: 100%;


        a {
          margin-top: 20px;
        }
      }
    }
  }

  a {
    color: #fff;
  }
}


.prepare-intro-text p {
  margin-bottom: 30px;
}
/* FAQ */

body.page-id-48 {
  main {
    #page-intro-box {
      #page-intro {
        p {
          margin-bottom: 40px;
        }
      }
    }
  }
}

#faq-faqs {
  color: $brand-primary;

  dl.accordion-menu {
    border-bottom: solid 1px #b1d47f;

    dt {
      border-top: solid 1px #b1d47f;
      position: relative;
      padding: 25px 0 27px;
      margin: 0;
      line-height: 30px;

      @include bp(mobile-tablet) {
        //width: 100%;
        font-size: 21px;
        line-height: 24px;
      }

      svg {
        position: absolute;
        left: -25px;
      }

      svg.svg-plusgreen {
        top: 35px;
        display: block;

        @include bp(mobile-tablet) {
          top: 31px;
        }
      }

      svg.svg-minusgreen {
        top: 41px;
        display: none;

        @include bp(mobile-tablet) {
          top: 37px;
        }
      }

      svg.svg-plusgreen-new {
        background: url('../images/svg/plusgreen.svg');
        background-position: left top;
        background-repeat: no-repeat;
        top: 35px;
        display: block;

        @include bp(mobile-tablet) {
          top: 31px;
        }
      }

      svg.svg-minusgreen-new {
        background: url('../images/svg/minusgreen.svg');
        background-position: left top;
        background-repeat: no-repeat;
        top: 41px;
        display: none;

        @include bp(mobile-tablet) {
          top: 37px;
        }
      }

      &.active {
        svg.svg-plusgreen-new {
          display: none;
        }

        svg.svg-minusgreen-new {
          display: block;
          max-width: 25px;
          max-height: 25px;
        }
      }
    }

    dd {
      display: none;
      padding: 9px 0 34px;
      color: $brand-tertiary;
      @include linkUnderLine();

      @include bp(mobile-tablet) {
        padding-top: 0;
      }

      h4 {
        color: $brand-primary;
        @include fontStyle();
        @include remy(16);
        font-weight: 500;
        margin-bottom: 15px;
        margin-top: 30px;
        line-height: 22px;
      }

      h3 {
        color: $brand-primary;
        @include fontStyle2();
        @include remy(18);
      }

      ul {
        list-style-type: disc;
        margin-bottom: 1em;
        margin-left: 15px;

        li {
          padding-bottom: 2px;
        }
      }

      p {
        // acf gives me unnecessary p
        &:first-child {
          // margin: 0;
        }
      }
      a {
        color: $brand-tertiary;
      }
    }
  }
}

