// ============================================================================
//  Custom reset
// ============================================================================
html {
  font-size: 100%;
  box-sizing: border-box;
  overflow-x: hidden;
}

*,
*::before,
*::after {
  box-sizing: inherit;
  // outline: transparent;
  // -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  @include fontStyle();
  @include remy(14);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  @include lineHeightFromPsd();
  margin: 0;
  position: relative;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 1em;
  @include fontStyle2();
  font-weight: normal;
  letter-spacing: -0.01em;
}

p {
  margin: 0 0 1em;
}

a {
  text-decoration: none;
  outline: none;
  -webkit-tap-highlight-color: transparent;
}

input {
  // Nasty Firefox hack for inputs http://davidwalsh.name/firefox-buttons
  &::-moz-focus-inner {
    border: 0;
    padding: 0;
    margin-top: -1px;
    margin-bottom: -1px;
  }
}

input[type='radio'],
input[type='checkbox'] {
  margin-right: 6px;
}

textarea,
button,
select {
  -webkit-appearance: none;
  -moz-appearance: none;
}

button {
  background: none;
  border: 0;
  cursor: pointer;
  font-size: 100%;
  padding: 0;
}

img {
  max-width: 100%;
  //width: 100%;
  width: auto;
  height: auto;
  display: block;
  vertical-align: middle;
}

blockquote {
  padding: 0;
}

figure {
  margin: 0;
}

dl, ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

li {
}

dd, dt {
  margin: 0;
  padding: 0;
}

.screen-reader-text {
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
}


#cbanner {
    width: 100%;
    height: auto;
    background-color:#64a900;
    box-sizing: border-box;
    padding: 15px 72px;
    margin: 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99999;
    visibility: hidden;
    opacity: 0;
    transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
    // cursor: pointer;

    .is--cookie__notaccepted & {
      visibility: visible;
      opacity: 1;
      position: relative;
    }


    #close-icon {
      position: absolute;
      right: 12px;
      top: 50%;
      transform: translateX(0) translateY(-50%) translateZ(0);
      cursor: pointer;
    }

    p {
      margin: 0;
      @include remy(14);
      font-weight: 200;
      line-height: 1.6em;
      @media only screen and (max-width: 768px) {
        @include remy(10);
      }
    }

    p, span, a {
      font-family: Whitney SSm A,Whitney SSm B, sans-serif;
      color: #fff;
    }

    a {
      color:#ffffff;
      text-decoration: none;
      position: relative;
      display: inline-block;

      &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background: #fff;
      }

      &:hover {
        text-decoration: none;
        &::before {
          display: none;
        }
      }
    }


    .button {
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      background: 0 0;
      border-radius: 0;
      -webkit-transition: all .1s ease-out;
      -o-transition: all .1s ease-out;
      transition: all .1s ease-out;
      color: #fff;
      border: 2px solid #ffffff;
      padding: 0 17px;
      line-height: 32px;
      height: 35px;
      margin: 0 5px;

      &:hover {
        border-color: #ffffff;
        background-color: #ffffff;
        color: #000;
      }
    }

    div {
      display: block;
      vertical-align: middle;
      text-align: center;
    }

    @media only screen and (max-width: 1200px) {
      padding: 15px 72px;
    }

    @media only screen and (max-width: 768px) {
      padding: 15px 45px;
      margin: 0;
    }

    @media only screen and (max-width: 800px) {
      div {
          display: block;
          vertical-align: middle;
      }
      .button {
        margin-left: 0;
        margin-top: 20px;
      }
    }
}