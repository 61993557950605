/* Portfolio */

body.portfolio,
body.parent-pageid-20 {
  main {
    color: $brand-primary;

    @include bp(mobile-tablet) {
      padding-bottom: 0;
    }

    a, a:visited {
      color: $brand-primary;
    }

    .quote-tab li {
      a, a:visited {
        color: $brand-secondary;
      }
    }
  }
}

.grid-thumbnail {
  margin: 0 auto;

  .page-template-template-plp &:not([style]),
  .tax-portfolio-category &:not([style]),
  .page-template-template-slp &:not([style]) {
    opacity: 0;
    visibility: hidden;
  }

  @include bp(mobile-tablet) {
    width: $content-mobile-inner-width;
  }
  .grid-sizer {
     width: percentage(7 / 22);

     @include bp(mobile-tablet) {
       width: 0;
     }
  }
  .gutter-sizer {
    width: percentage(0.5 / 22);

    @include bp(mobile-tablet) {
      width: 0;
    }
  }
  .grid-item {
    width: percentage(7 / 22);
    margin-bottom: 72px;

    @include bp(mobile-tablet) {
      width: 90%;
      margin: 0 5% 67px;
    }

    .grid-item-intro {
      width: percentage(5 / 7);
      margin: -3px auto 0;

      @include bp(mobile-tablet) {
        width: 100%;
      }

      h2 {
        @include remy(38);
        margin: 0 0 20px;
        line-height: 38px;

        @include bp(mobile-tablet) {
          display: none;
        }
      }

      p {
        margin: 0;

        &:first-child {
          line-height: 1;
          margin-bottom: 10px;

          @include bp(mobile-tablet) {
            display: none;
          }
        }
      }
    }

    &.grid-item-inner {
      color: $brand-primary;

      p, h2 {
        width: percentage(5 / 7);
        margin: 0 auto;

        @include bp(mobile-tablet) {
          width: 100%;
        }

        a {
          display: inline;
          padding-bottom: 6px;
          background-image: linear-gradient($brand-primary, $brand-primary);
          background-position: 0% 100%;
          background-repeat: no-repeat;
          background-size: 100% 1px;
          transition: all 0.2s ease-out;

          @media (hover: hover) {
            &:hover {
              background-size: 0% 1px;
            }
          }
        }
      }

      h2 {
        @include remy(26);
        line-height: 1.15;
        margin-bottom: 18px;
      }
    }
  }

  .image {
    margin-bottom: 24px;

    @include bp(desktop-all) {
      margin-bottom: 22px;
    }

    a {
      display: block;
      overflow: hidden;

      img {
        transition: all 0.6s ease-out;
      }

      @media (hover: hover) {
        &:hover img {
          transform: scale(1.05);
        }
      }
    }
  }
}

nav#pagination {
  display: table;
  padding-top: 65px;
  margin-bottom: 40px;
  @include clearfix();
  @include fontStyle2();
  @include remy(26);

  @include bp(mobile-tablet) {
    padding-top: 53px;
  }

  .pagination-left,
  .pagination-right {
    width: percentage(7 / $grid-22);

    @include hoverFade();

    @include bp(mobile-tablet) {
      margin: 0;
      width: 50%;
      position: relative;
    }
  }

  .pagination-left {
    @include bp(mobile-tablet) {
     padding-left: 20px;
    }

    svg {
      margin: 0 15px 0 4px;

      @include bp(mobile-tablet) {
        @include abs(12px, auto, auto, 0);
        margin: 0;
      }
    }
  }
  .pagination-right {
    @include bp(mobile-tablet) {
      padding-right: 20px;
    }

    svg {
      margin: 0 4px 0 15px;

      @include bp(mobile-tablet) {
        @include abs(12px, 0, auto, auto);
        margin: 0;
      }
    }
    float: right;
    text-align: right;
  }
}

#portfolio-experiences {
  position: relative;
  height: 940px;

  @include bp(mobile-tablet) {
    margin-top: 100px;
    height: auto;
  }

  .quote-bg-img {
    @include bgFitFull();
    position: absolute;
    top: 140px;

    @include bp(mobile-tablet) {
      @include bgFitFull(305px);
      top: -50px;
    }

    //@include bp(tablet) {
    //  top: percentage(140 / $content-max-width);
    //}
    //@include bp(mobile-tablet) {
    //  top: percentage(140 / $content-max-width);
    //}
  }

  .quote {
    position: relative;
    background-color: $brand-primary-transparent;
    color: $brand-secondary;
    padding: 95px 0 80px;
    min-height: 800px;

    @include bp(mobile-tablet) {
      padding: 60px 0 60px;
      min-height: 500px;
    }

    // TODO: Move this
    .quote-bg {
      position: absolute;
      width: 100%;
      z-index: -1;
      height: 140px;
      background-color: $brand-primary;
      top: 0;
      left: 0;
    }

    .quote-inner {
      display: table;

      .quote-left,
      .quote-right {
        display: inline-block;
        vertical-align: top;
      }

      .quote-left {
        width: percentage(13 / 20);
        margin-right: percentage(7 / 20);
        min-height: 440px;

        @include bp(mobile-tablet) {
          width: 100%;
          min-height: 320px;
        }

        .quote-menu {
          display: table;
          padding-bottom: 35px;

          .quote-title, .quote-tab {
            display: inline-block;
          }

          .quote-tab {
            li {
              display: inline;
              margin-left: 20px;

              a.active,
              a.tabBoxLink1 {
                position: relative;
                display: inline-block;
                // text-decoration: line-through;

                &:after {
                  content: "";
                  height: 1px;
                  width: 28px;
                  background: #fff;
                  position: absolute;
                  top: 50%;
                  right: 50%;
                  transform: translate(50%, -50%);
                }
              }
            }
          }
        }

        .quote-section {
          p.quote-text {
            @include fontStyle2();
            @include remy(38);
            //width: percentage(13 / 20);
            line-height: percentage(44 / 38);
            margin-bottom: 30px;
            @include bp(mobile-tablet) {
              @include remy(21);
              line-height: 26px;
            }
          }

          p.quote-author {
            margin: 0;
          }
        }

        .tabBox {
          display: none;
        }

        #tabBox1 {
          display: block;
        }
      }

      .quote-right {
        width: percentage(6 / 20);
        margin-top: 60px;
        margin-left: percentage(14 / 20);

        @include bp(desktop) {
          width: percentage(7 / 20);
          margin-left: percentage(13 / 20);
        }

        @include bp(tablet) {
          width: percentage(9 / 20);
          margin-left: percentage(11 / 20);
        }

        @include bp(mobile-tablet) {
          width: 100%;
          margin-top: 65px;
          margin-left: 0;
        }

        .certifications-title {
          margin-bottom: 45px;

          @include bp(mobile-tablet) {
            margin-bottom: 15px;
          }
        }

        .quote-certifications {

          img {
            width: 38px;
            margin-right: 18px;
            display: inline-block;

            &.u-img-2x {
              width: 60px;
            }

            &:last-of-type {
              margin-right: 0;
            }

            @include bp(mobile) {
              max-width: 35px;
            }
          }
        }
      }
    }
  }
}

.portfolio-link {
  cursor: pointer;
  position: relative;
  &:after {
    content: '';
    width: 100%;
    position: absolute;
    left: 0;
    bottom: -3px;
    border-width: 0 0 1px;
    border-style: solid;
  }

  &:hover {
    &:after {
      width: 0;
      animation: hundredToZero 0.25s ease-out;
    }

    }

}
