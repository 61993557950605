// margins and sizings
$mobile-margin: 17px;
$desktop-margin: 25px;

$gutter-size: 32px;

.container {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  //width:960px;
  //background: #F0F0F0;
  margin: 0 auto;
}

.container--full {
  // width: 100vw; this causes horizontal scroll bar on Edge
  width: 100%;
}

.content-wrap {
  padding-right: 100px;
  padding-left: 100px;
}

.content-wrap-half {
  padding-left: 100px;
  width: 50%;
}

// Grid system

// choose whatever you want to use

.grid-22 {
  margin: 0 auto;


  @include bp(desktop-limit) {
    width: $grid-22-max-width;
  }

  @include bp(desktop) {
    //padding: 0 $desktop-margin;
    width: percentage( $grid-22 / $grid-max);
  }

  @include bp(tablet) {
    //padding: 0 $desktop-margin;
    width: percentage( $grid-22 / $grid-max);
  }

  @include bp(mobile) {
    //padding: 0 $mobile-margin;
    width: percentage( 682 / 750);
  }
}

.grid-20 {
  margin: auto;

  @include bp(desktop-limit) {
    width: $grid-20-max-width;
  }

  @include bp(desktop) {
    width: percentage( $grid-20 / $grid-22);
  }

  @include bp(tablet) {
    width: percentage( $grid-20 / $grid-22);

    &.mobile-disable {
      width: 100%;
    }
  }

  @include bp(mobile-tablet) {
    width: $content-mobile-inner-width;

    &.mobile-disable {
      width: 100%;
    }
  }
}

.wrap {
  @include bp(desktop) {
    //background: green;
  }

  @include bp(tablet) {
    //background: yellow;
  }

  @include bp(mobile) {
    //background: blue;
  }
}
