/* Terms */

// terms
body.page-id-54 {
  main {
    color: $brand-primary;

    @include linkPrimaryColor();

    h2 {
      @include remy(38);
      line-height: 1;
      margin-bottom: 40px;

      @include bp(mobile-tablet) {
        @include fz_vw(28);
        margin-bottom: 1em;
      }
    }
  }
}

.terms-list,
.privacy-list {
  display: table;

  h3 {
    display: inline-block;
    vertical-align: top;
    width: percentage(5 / 20);
    @include remy(20);
    line-height: 1;
    margin-bottom: 40px;
    @include fontStyle();

    @include bp(mobile-tablet) {
      @include fz_vw(20);
      margin-bottom: 1em;
      margin-left: 0;
      width: 100%;
    }

  }
}

.terms-list {
  display: table;
  margin-bottom: 80px;

  @include bp(mobile-tablet) {
    margin-bottom: 3em;
  }

  h3 {
    @include fontStyle();
    line-height: 1;
    margin-bottom: 1rem;
  }

  .terms-list-inner {
    color: #34382f;
    vertical-align: top;
    display: inline-block;
    margin-left: percentage(3 / 20);
    width: percentage(12 / 20);

    @include bp(mobile-tablet) {
      margin-left: 0;
      width: 100%;
    }

    ul {
      display: table;
      padding-bottom: 1rem;

      li.item {

        @include bp(mobile-tablet) {
          width: 100%;
        }

        ul {
          margin-bottom: 1rem;
          li {
            display: block;
            @include strikeLineLeft(11px, 11px, 13px);
            margin-bottom: 0;
            position: relative;
            padding-left: 27px;
            @include breakWord();

            span {
              @include abs(0, auto, 0, 0);
              position: absolute;
            }

            @include bp(mobile-tablet) {
              @include fz_vw(18);
              @include strikeLineLeft(12px, 11px, 13px);
              padding-left: 27px;

              span {
                @include abs(0, auto, 0, 0px);
              }
            }
          }
        }
      }
    }
  }
}

.privacy-list {
  display: table;

  .privacy-list-inner {
    color: #34382f;
    vertical-align: top;
    display: inline-block;
    margin-left: percentage(3 / 20);
    width: percentage(12 / 20);

    @include bp(mobile-tablet) {
      margin-left: 0;
      width: 100%;
    }
  }
}
