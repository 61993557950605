$ease-in-sine: cubic-bezier(0.47, 0, 0.745, 0.715);
$ease-out-sine: cubic-bezier(0.39, 0.575, 0.565, 1);
$ease-in-expo: cubic-bezier(0.95, 0.05, 0.795, 0.035);
$ease-out-expo: cubic-bezier(0.19, 1, 0.22, 1);

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes appear {
  0% {
    display: none;
  }
  100% {
    display: block;
  }
}

@keyframes alpha0to1 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes alpha0to85 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1.0;
  }
}


@keyframes alpha1to0 {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes hundredToZero {
  0% {
    width: 100%;
  }
  100% {
    width: 0;
  }
}

@keyframes zeroToHundred {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
