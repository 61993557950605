// Colors
$brand-primary: #64a900;
$brand-primary-transparent: rgba(103, 174, 0, 0.9);
$brand-secondary: #FFFFFF;
$brand-tertiary: #34382f;

$brand-primary-bg: #67ae00;
$brand-primary-light-line: #b1d47f;

$brand-footer: #F8F8F8;

  // Grid
$grid-max: 24;
$grid-22: 22;
$grid-20: 20;

// Size
$content-max-width: 1280;
$content-mobile-max-width: 750;
$content-mobile-inner-width: percentage( 582 / (750-34 * 2));
$grid-20-mobile-width: 582px;


$grid-22-max-width: 1174px;
$grid-20-max-width: 1066.66666667px;

$hero-image-height:       800px;

$pc-top-padding: 180px;

// pc line-height from psd
// 22/16
