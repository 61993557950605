body.error404 {
  main {
    #page-intro-box {
      padding-top: 118px;

      #page-intro {
        h1 {
          color: $brand-primary;
          @include remy(160);
          line-height: 1;
          margin-bottom: 54px;
        }

        p {
          color: $brand-primary;
          margin-bottom: 40px;
        }

        #btnTakeHome {
          width: percentage(3 / 8);
        }
      }
    }
  }
}

//Search

body.search-no-results {
  main {
    #page-intro-box {
      padding-top: 158px;

      #page-intro {
        h1 {
          color: $brand-primary;
          @include remy(78);
          line-height: 1;
          margin-bottom: 54px;
        }

        p {
          color: $brand-primary;
          margin-bottom: 40px;
        }

        #btnTakeHome {
          width: percentage(3 / 8);
        }
      }
    }
  }
}

body.search-results {
  p.entry-meta {
    margin: 0;
    @include linkPrimaryColor();
    @include linkUnderLine();

    a {
      margin-left: 15px;
    }
  }
}
