/* Services */

body.services {
  main {
    color: $brand-primary;

    a, a:visited {
      color: $brand-primary;
    }
  }
}

#service-category-menu {
  display: table;
  border: none;
  margin-bottom: 42px;

  li {
    display: inline;
    margin-right: 15px;

    @include hoverFade();
  }

  @include bp(mobile-tablet) {
    display: none;
  }
}

.services-section {
  .services-img {
    @include bgFitFull();

    @include bp(mobile-tablet) {
      @include bgFitFull(306px);
    }

    @include bp(desktop-limit) {
      height: 1000px;
    }

    @media screen and (min-width: 1800px) {
      height: 1200px;
    }
  }

  .services-contents {
    //padding-bottom: 149px;
    padding-bottom: 250px;

    @include linkUnderLine();

    a {
      display: inline-block;

      &:after {
        bottom: 0;
      }
    }

    @include bp(mobile-tablet) {
      padding-bottom: 5em;
      border-bottom: solid 30px $brand-primary;
    }

    h2, p {
      width: 320px;

      @include bp(mobile-tablet) {
        width: 100%;
      }
    }

    h2 {
      @include remy(48);
      line-height: 1;
      margin: 61px 0 24px;

      @include bp(mobile-tablet) {
        @include remy(37);
      }
    }

    p {
      margin-top: 0;
    }

    .accordion-menu {
      width: 320px;//percentage(13 / 20);
      padding-bottom: 25px;

      @include bp(mobile-tablet) {
        width: 100%;
      }

      dt {
        position: relative;
        cursor: pointer;
        @include fontStyle2();
        @include remy(26);
        padding-bottom: 8px;

        @include bp(mobile-tablet) {
          padding-left: 0;
          width: 100%;
        }

        h3 {
          @include remy(46);
          margin-bottom: 3px;
        }

        p {
          @include remy(28);
          margin-bottom: 20px;
        }

        svg {
          position: absolute;
          left: -24px;
        }

        svg.svg-plusgreen {
          top: 12px;
          display: block;
        }

        svg.svg-minusgreen {
          top: 18px;
          display: none;
        }

        &.active {
          svg.svg-plusgreen {
            display: none;
          }

          svg.svg-minusgreen {
            display: block;
          }
        }
      }

      dd {
        display: none;
        padding-bottom: 18px;

        @include bp(mobile) {
          padding-left: 0;
        }

        p {
          margin: 4px 0 8px;
        }

        li {
          position: relative;
          @include remy(12);

          &:before {
            position: absolute;
            z-index: 0;
            content: '';
            display: block;
            top: 8px;
            left: -10px;
            width: 3px;
            height: 1px;
            background: $brand-primary;
          }
        }
      }
    }
  }

  &:last-of-type {
    .services-contents {
      padding-bottom: 0;
      border-bottom: none;
    }
  }

  &:nth-child(odd) {
    .services-contents {
      h2, p {
        margin-left: percentage(13 / 20);

        @include bp(mobile-tablet) {
          margin-left: 0;
        }
      }
    }
  }
}

.single-services {
  .header-image {
    @include bp(mobile-tablet) {
      height: 347px;
      background-position: center !important;
    }
  }

  .featured-text {
    position: relative;
    margin-top: -85px;

    @include bp(desktop-all) {
      margin-top: -141px;
    }
  }
}
