$mobile-width: 767px;
$tablet-width: 768px;
$desktop-width: 1024px;
$desktop-limit-width: 1280px;

$desktop-content-width: 1600px;

@mixin bp($point) {
  @if $point == desktop-limit {
    @media (min-width: #{$desktop-limit-width}) {
      @content;
    }
  } @else if $point == desktop {
    @media (min-width: #{$desktop-width}) and (max-width: #{$desktop-limit-width - 1px}) {
      @content;
    }
  }  @else if $point == desktop-all {
    @media (min-width: #{$desktop-width}) {
      @content;
    }
  }@else if $point == tablet {
    @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
      @content;
    }
  } @else if $point == mobile {
    @media (max-width: #{$mobile-width}) {
      @content;
    }
  } @else if $point == tablet-desktop {
    @media (min-width: #{$tablet-width}) {
      @content;
    }
  } @else if $point == mobile-tablet {
    @media (max-width: #{$desktop-width - 1px}) {
      @content;
    }
  }
}

@mixin orientation($orientation) {
  @if $orientation == landscape {
    @media all and (orientation:landscape) {
      @content;
    }
  } @else {
    @media all and (orientation:portrait) {
      @content;
    }
  }
}

@mixin remy($pxval: 16) {
  font-size: $pxval * 1px;
  font-size: ($pxval / 16) * 1rem;
}

@function get_vw($size, $viewport:750){
  $rate: 100 / $viewport;
  @return $rate * $size * 1vw;
}

// @mixin fz_vw($font_size:10){
//   font-size: $font_size * 1px;
//   font-size: get_vw($font_size);
// }

@mixin fz_vw($pxval: 16) {
  font-size: $pxval * 1px;
  font-size: ($pxval / 16) * 1rem;
}

@mixin abs-center() {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin clear-abs-center() {
  position: relative;
  top: initial;
  left: initial;
  transform: none;
}

/* font */

@mixin lineHeightFromPsd($fontSize: 14, $fontLeading: 22 ) {
  $height: $fontSize + $fontLeading / 2;
  line-height: $fontLeading / $fontSize;
}

@mixin fontStyle() {
  font-family: "Whitney SSm A", "Whitney SSm B", sans-serif;
  font-weight: 500;
}

@mixin fontStyle2() {
  font-family: "Quarto A", "Quarto B", serif;
}

@mixin clearfix() {
  &:after {
    content: " ";
    display: block;
    clear: both;
  }
}

@mixin breakWord() {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
  word-break: break-word;
  hyphens: auto;
}

/* position */
@mixin pos($top, $right, $bottom, $left) {
  @if $top != null {
    top: $top
  }

  @if $bottom != null {
    bottom: $bottom
  }

  @if $right != null {
    right: $right
  }

  @if $left != null {
    left: $left
  }
}

@mixin abs($top, $right, $bottom, $left) {
  position: absolute;
  @include pos($top, $right, $bottom, $left);
}

/* margin */
@function verticalGap($fontSize, $lineHeight: 1.375) {
  @return (($fontSize * $lineHeight) - $fontSize) / 2;
}

@mixin marginBottom($margin, $fontSize, $lineHeight: 1.375, $nextFontSize: 16, $nextLineHeight: 1.375) {
  $myBottomGap: verticalGap( $fontSize, $lineHeight );
  $nextTopGap: verticalGap( $nextFontSize, $nextLineHeight );
  margin-bottom: ($margin - $myBottomGap - $nextTopGap ) + px;
}

/* padding */
@mixin topPadding() {
  padding-top: 168px;

  @include bp(mobile) {
    padding-top: 110px;
  }
}

/* bg */
@mixin bgFit() {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}

@mixin bgFitFull($height: 800px) {
  @include bgFit();
  width: 100%;
  height: $height;
}

@mixin bgFitFullMobile($height: 800px) {
  //max-height: 200%;
  //@include bgFitFull($height);
  width: 100%;
  background-size: 250%;
  background-position: center top;
  //min-height: 100%;
  //height: 1350px;
}

@mixin bgColorUnderneath() {
  &:before {
    position: absolute;
    z-index: -1;
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background: $brand-primary;
  }
}

// Spacer
@mixin spacer($height) {
  &:before {
    content: '';
    display: block;
    width: 100%;
    height: $height;
  }
}

// Link
@mixin linkPrimaryColor() {
  a, a:visited {
    color: $brand-primary;
  }
}

@mixin linkSecondaryColor() {
  a, a:visited {
    color: $brand-secondary;
  }
}

@mixin linkUnderLine($margin:-3px) {
  a {
    text-decoration: none;
    position: relative;
  }

  a, a:visited {
    &:after {
      content: '';
      width: 100%;
      position: absolute;
      left: 0;
      bottom: $margin;
      border-width: 0 0 1px;
      border-style: solid;
    }
  }

  a:hover {
    &:after {
      width: 0;
      animation: hundredToZero 0.25s ease-out;
    }
  }
}

@mixin hoverFade() {
  a:hover {
    opacity: 0.7
  }
}

// TODO: no important
@mixin hoverPrimaryColor() {
  a:hover {
    background-color: $brand-primary;
    color: $brand-secondary!important;
    transition: background-color 0.25s ease-out;
    transition: color 0.25s ease-out;
  }
}

// TODO: no important
@mixin hoverSecondaryColor() {
  a:hover {
    background-color: $brand-secondary;
    color: $brand-primary!important;
    transition: background-color 0.25s ease-out;
    transition: color 0.25s ease-out;
  }
}


@mixin hoverFadeBtn() {
  &:hover {
    opacity: 0.7
  }
}

@mixin hoverUnderLine($margin:-5px) {
  a {
    text-decoration: none;
    position: relative;
  }

  &.active a, &.current-menu-item a,
  a:hover {
    &:after {
      content: '';
      width: 100%;
      position: absolute;
      // left: 3px;
      bottom: $margin;
      border-width: 0 0 1px;
      border-style: solid;
      animation: zeroToHundred 0.25s ease-out;
    }
  }

  a:hover {
    &:after {
      left: 0;
    }
  }

  &.active a, &.current-menu-item a {
    &:after {
      left: 0;
    }
  }
}

@mixin hoverMidLine($bottom:40%, $left:30px, $top:0 ) {
  a {
    text-decoration: none;
    position: relative;
    padding: 0 ($left - 9px) 0 ($left + 6px);

    &:after {
      content: '';
      width: $left;
      position: absolute;
      left: 3px;
      bottom: $bottom;
      border-width: 0 0 1px;
      border-style: solid;
    }
  }

  &.active a, &.current-menu-item a,
  a:hover {
    &:after {
      content: '';
      width: 100%;
      position: absolute;
      left: 3px;
      bottom: $bottom;
      border-width: 0 0 1px;
      border-style: solid;

      animation: zeroToHundred 0.45s ease;
    }
  }

  @include bp(mobile) {
    $leftMobile: 16px;

    a {
      padding: 0 ($leftMobile - 9px) 0 ($leftMobile + 6px);

      &:after {
        width: $leftMobile;
        left: 3px;
        bottom: auto;
        top: 18px;
      }
    }

    &.active a, &.current-menu-item a,
    a:hover {
      &:after {
        left: 3px;
        bottom: 40%;
      }
    }
  }

}

@mixin strikeLine() {
  position: relative;

  a {
    text-decoration: none;
    position: relative;
  }

  &.active, &.current-menu-item a,
  a:hover {
    &:after {
      content: '';
      width: 180%;
      position: absolute;
      left: -40%;
      bottom: 50%;
      border-width: 0 0 1px;
      border-style: solid;
    }
  }
}

@mixin strikeLineLeft($left, $top:55%, $width: 12px) {
  position: relative;

  &:after {
    content: '';
    width: $width;
    position: absolute;
    left: $left;
    top: $top;
    border-width: 0 0 1px;
    border-style: solid;
  }
}

@mixin boxLinkWhite($width) {
  @include hoverPrimaryColor();
  a {
    display: block;
    color: $brand-primary;
    border: solid 1px $brand-primary;
    width: $width;
    text-align: center;
    padding: 1em 0;
  }
}

//@mixin boxLinkWhite($width) {
//  @include hoverPrimaryColor();
//
//  a {
//    display: block;
//    border: solid 1px $brand-primary;
//    width: $width;
//    text-align: center;
//    padding: 1em 0;
//  }
//}


@mixin boxLinkWithIcon() {
  @include hoverFade();

  a {
    display: block;
    padding: 0.8em 1em;
    border: solid 1px $brand-primary;
    @include clearfix();

    @include bp(desktop-all) {
      line-height: 28px;
    }

    span {
      display: inline-block;
      width: percentage(11 / 12);
      float: left;
      padding-right: 10px;
    }

    svg {
      float: right;
      vertical-align: middle;
      margin-top: 2px;
    }
  }
}

@mixin boxLinkWithAdobeIcon() {
  @include hoverSecondaryColor();
  @include linkSecondaryColor();

  a {
    display: block;
    @include clearfix();
    padding: 7px;
    border: solid 1px $brand-secondary;

    span {
      width: percentage(10 / 12);
      float: left;
      line-height: 32.176px;
      margin-left: 5px;
    }

    svg {
      float: right;
    }
  }
}

@mixin linkWithIcon() {
  svg {
    vertical-align: middle;
    margin-left: 10px;
  }
}

@mixin addFooterBorder($padding) {
  footer {
    margin-top: $padding;
  }
}
