.note {
  @include remy(12);
}

.mb0 {
  margin-bottom: 0;
}

.display-table {
  display: table;
}

.table-cell {
  display: table-cell;
}

@include bp(tablet-desktop) {
  .mobile-view {
    display: none;
  }
}

@include bp(mobile) {
  .pc-view {
    display: none;
  }
}

.u-no-scroll {
  overflow: hidden;
  height: 90vh;
}
