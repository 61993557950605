body.page-template-content-page-our-50th, body.page-template-content-page-company-history {
  main {
    color: $brand-primary;

    @include linkPrimaryColor();

    hr {
      height: 1px;
      margin: 8px 0;
      background: $brand-primary;
      border: none;

      @include bp(mobile-tablet) {
        margin: 0;
      }
    }
    .company-intro {
      color: $brand-secondary;
    }
  }

  .company-title {
    h1 {
      margin-bottom: 147px;

      @include bp(mobile) {
        margin-top: 160px;
        margin-bottom: 44px;
      }
    }

    .watch-video {
      @include boxLinkWhite(160px);
      margin-top: -84px;
      margin-bottom: 72px;

      @include bp(mobile) {
        margin-top: 0;
        margin-bottom: 44px;
      }

      a {
        margin: 0 auto;
        cursor: pointer;

        @include bp(mobile) {
          width: 90%;
        }
      }
    }
  }

  /* video overlay */
  .lightbox {
    &.show {
      overflow:hidden;
    }

    .svg-x {
      background-position:100% 32.48%
    }
  }
  .video-lightbox {
    &.show {
      overflow:hidden;
    }
    .lightbox-inner {
      @include bp(mobile-tablet) {
        padding-top:0;
      }
    }
    .lightbox-button-box {
      height:0;

       @include bp(mobile-tablet) {
        top:-62px;

        .grid-22 {
          .button.close {
            margin:0;
          }
        }
      }
    }
    .lightbox-inner-box {
      height:100%;

      .grid-22 {
        @include bp(mobile-tablet) {
          width:100%;
        }
      }
    }
    .iframe-wrapper {
      margin:0 auto;
    }
    .iframe {
      position:relative;
      width:100%;
      height:0;
      padding-bottom:56.25%;

      iframe {
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
        display:block;
      }
    }
  }

  /* intro */
  .new-parallax {
    position: relative;
    height: 800px;

    &.quote-bg-img {
      margin-top:168px;
    }

    .logo-50th {
      position: absolute;
      top: 160px;
      left: 50%;
      width: 240px;
      height: auto;
      margin-left: -120px;
    }

    @include bp(mobile) {
      height: 400px;

      &.quote-bg-img {
        height: 350px;
        margin-top: 100px;
      }

      .logo-50th {
        top: 41px;
        width: 148px;
        margin-left: -74px;
      }
    }
  }
  .company-intro {
    margin-bottom: 58px;
    padding-bottom: 132px;

    @include bp(mobile-tablet) {
      margin-bottom: 0;
      padding-top: 40px;
      padding-bottom: 76px;
    }

    .grid-20 {
      .company-hero-left {
        width: percentage(15 / 20);

        @include bp(mobile-tablet) {
          width: 100%;
        }

        h2 {
          @include remy(38);
          line-height: percentage(44 / 38);

          @include bp(mobile-tablet) {
            margin:26px 0;
            @include remy(21);
            line-height: percentage(26 / 21);
          }
        }
      }
    }
  }
  /* end intro */

  /* stories */
  #blog-thumbnails {
    @include bp(mobile-tablet) {
      width: 90.9333333333%;
    }

    .blog-item {
      .entry-header {
        p.entry-meta {
          display: none;
        }
      }
    }
  }
  /* end stories */

  /* carousel */

  .carousel {
    //margin-bottom: 150px;

    .page-intro-box {
      padding-bottom: 34px;
    }

    @include bp(mobile) {
      //margin-bottom: 100px;
    }
  }

  /* gallery */
  .page-intro-box {
    padding-top: 92px;
    padding-bottom: 76px;

    .page-intro {
      h1 {
        margin-bottom: 40px;

        @include bp(mobile) {
          margin-bottom: 35px;
        }
      }
    }

    @include bp(mobile-tablet) {
      padding-top: 70px;
      padding-bottom: 2px;
    }
  }
  .grid-thumbnail.gallery {
    margin-top: -38px;
    margin-bottom: 22px;

    @include bp(mobile-tablet) {
      //width: 90.9333333333%;
      margin-top: 0;
      margin-bottom: 5px;
    }

    .grid-item {
      &.hide {
        display: none;
      }
      a + p {
        display: none;
      }
      .portfolio-link:after {
        display: none;
      }

      @include bp(mobile-tablet) {
        //width: 100%;
        //margin-left: 0;
        //margin-right: 0;
      }
    }

  }
  .load-more {
    @include boxLinkWhite(160px);
    margin-bottom:100px;

    @include bp(mobile-tablet) {
      margin-bottom: 150px;
    }

    a {
      margin:0 auto;
      cursor:pointer;

      @include bp(mobile) {
        width: 90%;
      }
    }
  }
  /* end gallery */

  /* social */
  #category-footer-share {
    margin-top: -400px;
    margin-bottom: 60px;

    .quote {
      .quote-inner {
        //height: 800px;
        //padding-top: 184px;
        //padding-bottom: 0;
      }
    }

    @include bp(mobile-tablet) {
      margin-top: -400px;

      .quote {
        .quote-inner {
          height: auto;
          padding-top: 42px;
          padding-bottom: 114px;
        }
      }
    }
  }
  /* end social */
}

body.page-template-content-page-our-50th-story {
  main {
    .entry-header {
      a {
        margin-left:0;
      }
    }
  }
}


.carousel {
  p {
    a {
      position: relative;

      &:before {
        content: "";
        width: 100%;
        position: absolute;
        left: 0;
        bottom: -3px;
        border-width: 0 0 1px;
        border-style: solid;
      }

      &:hover {
        &:before {
          width: 0;
          animation: hundredToZero .25s ease-out;
        }
      }
    }
  }
}

/// SLICK SLIDER

.carousel-slider {
  // margin-bottom: 250px;
}

.external-link {
  position: relative;
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 120px;

  &:before {
    content: "";
    width: 100%;
    position: absolute;
    left: 0;
    bottom: -3px;
    border-width: 0 0 1px;
    border-style: solid;
  }

  &:after {
    position: absolute;
    right: -20px;
    top: 2px;
    content: url('../images/external-arrow.svg');
  }

  &:hover {
    &:before {
      width: 0;
      animation: hundredToZero .25s ease-out;
    }
  }

  @include bp(mobile) {
    margin-bottom: 70px;
  }
}

.slick-slider {
  position: relative;
  width: 100%;
  height: 656px;
  margin-bottom: 160px;
  position: relative;
  // overflow-x: hidden;

  display: block;
  box-sizing: border-box;

  user-select: none;

  -webkit-touch-callout: none;
  -khtml-user-select: none;
        touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;

  @include bp(mobile) {
    height: 258px;
  }

  button.slick-prev {
    color: transparent;
    position: absolute;
    left: 0;
    bottom: 0;
    // transform: translateY(-43%);
    cursor: pointer;
    z-index: 99;
    border: 0;
    padding: 0;
    background: transparent;
    // border: 1px solid green;
    height: 100%;
    width: 50px;

    // &::after {
    //   content: url('../images/left-arrow.svg');
    //   position: absolute;
    //   left: 0;
    // }

    // &:hover {
    //   background: transparent;
    // }
  }

  button.slick-next {
    color: transparent;
    position: absolute;
    right: 0;
    bottom: 0;
    // transform: translateY(-43%);
    cursor: pointer;
    z-index: 99;
    border: 0;
    padding: 0;
    background: transparent;
    // border: 1px solid green;
    height: 100%;
    width: 50px;

    // &::after {
    //   content: url('../images/right-arrow.svg');
    //   position: absolute;
    //   right: 0;
    // }

    // &:hover {
    //   background: transparent;
    // }
  }
}

// Default Variables

// Slick icon entity codes outputs the following
// "\2190" outputs ascii character "←"
// "\2192" outputs ascii character "→"
// "\2022" outputs ascii character "•"

$slick-font-path: "./fonts/" !default;
$slick-font-family: "slick" !default;
$slick-loader-path: "./" !default;
$slick-arrow-color: white !default;
$slick-dot-color: black !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-prev-character: "\2190" !default;
$slick-next-character: "\2192" !default;
$slick-dot-character: "\2022" !default;
$slick-dot-size: 6px !default;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;


.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
       -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
         -o-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before,
.slick-track:after {
  display: table;
  content: '';
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 656px;
  min-height: 1px;
  overflow-x: hidden;

  @include bp(mobile) {
    height: 258px;
  }
}

[dir='rtl'] .slick-slide {
  float: right;
}

.slick-slide {
  img {
    display: block;
    height: 656px;
    width: 100%;
    object-fit: cover;

    @include bp(mobile) {
      height: 258px;
    }
  }
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Dots */

.slick-dotted.slick-slider {
  margin-bottom: 30px;

  @include bp(mobile) {
    margin-bottom: 80px;
  }
}

.slick-dots {
  position: absolute;
  bottom: -60px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
  li {
    position: relative;
    display: inline-block;
    height: 10px;
    width: 10px;
    margin: 0 5px 12px;
    padding: 0;
    cursor: pointer;
    button {
      border: 0;
      background: transparent;
      display: block;
      height: 10px;
      width: 10px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer;
      &:hover, &:focus {
        outline: none;
        &:before {
          opacity: $slick-opacity-on-hover;
        }
      }
      &:before {
        position: absolute;
        top: 0;
        left: 0;
        content: '';
        border-radius: 50%;
        width: 10px;
        height: 10px;
        line-height: 10px;
        text-align: center;
        border: 1px solid $brand-primary;
        background: white;
      }
    }
    &.slick-active button:before {
      background: $brand-primary;
    }
  }

  @include bp(mobile) {
    bottom: -50px;
  }
}
