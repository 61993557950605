/* Blog */

body.blog,
body.category,
body.tag,
body.search {
  main {
    color: $brand-primary;

    a, a:visited {
      color: $brand-primary;
    }
    #page-intro {
      h1 {
        @include remy(80);
        line-height: percentage(70 / 80);
        margin-bottom: 30px;

        @include bp(mobile-tablet) {
          @include remy(48);
        }
      }
    }

    .quote-tab li {
      a, a:visited {
        color: $brand-secondary;
      }
    }
  }
}

#blog-thumbnails {
  display: block;
  @include clearfix();

  .post {
    display: block;
    width: percentage(10.5 / 22);
    margin-right: percentage(1 / 22);
    float: left;
    margin-bottom: 0;

    @include bp(mobile-tablet) {
      width: 100%;
      margin-right: 0;
    }

    article {
      width: 100%;
    }

    &:nth-child(2n) {
      margin-right: 0;
    }
  }

  .blog-item {
    .entry-header {
      width: percentage(7 / 10.5);
      margin-left: percentage(1 / 10.5);
      margin-top: 33px;
      margin-bottom: 104px;

      h2 {
        @include remy(26);
        margin-bottom: 16px;
        line-height: percentage(30/26);
        letter-spacing: 0;
      }

      p.entry-meta {
        margin: 0;
        @include linkPrimaryColor();
        @include linkUnderLine();

        a {
          margin-left: 15px;
        }
      }

      @include bp(mobile-tablet) {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
        padding-left: 20px;
        padding-right: 27px;
        margin-bottom: 75px;

        h2 {
          max-width: 100%;
        }
      }
    }

    img {
      width: 100%;
      height: 450px;
      object-fit: cover;

      @include bp(mobile) {
        height: auto;
      }
    }
  }
}

ul.numberedPagination {
  display: table;
  margin: 0 auto 45px;

  li {
    display: inline;
    margin-right: 20px;
    position: relative;

    &:last-child {
      margin-right: 0;
    }

    .current,
    a:hover {
      // text-decoration: line-through;
    }

    @include strikeLine();

    &.active {
      &:after {
        content: '';
        width: 180%;
        position: absolute;
        left: -40%;
        bottom: 50%;
        border-width: 0 0 1px;
        border-style: solid;
      }
    }
  }

  @include bp(mobile-tablet) {
    margin-top: 50px;
  }
}

nav#pagination {
  display: table;
  margin-bottom: 40px;

  @include fontStyle2();
  @include remy(26);

  .pagination-left,
  .pagination-right {
    display: inline-block;
    width: percentage(7 / $grid-22);

    @include hoverFade();
  }

  .pagination-right {
    svg {
      margin: 0 4px 0 15px;
    }
  }
  .pagination-left {
    svg {
      margin: 0 15px 0 4px;
    }
  }
  .pagination-right {
    margin-left: percentage(8 / $grid-22);
    text-align: right;
  }
}

#category-footer-share {
  position: relative;
  height: 940px;
  width: 100%;
  // padding-top: 100px;

  .category & {
    overflow: hidden;
  }

  @include bp(mobile-tablet) {
    height: auto;
    padding-bottom: 60px;
    margin-top: -100px;
    // height: auto;

  }

  .mobile & {
    height: auto;
    margin-bottom: 60px;
  }

  .quote-bg-img {
    @include bgFitFull(882px);
    position: absolute;
    top: 52px;

    @include bp(mobile-tablet) {
      @include bgFitFull(305px);
    }

    @include bp(desktop) {
      top: 140px;

      .category & {
        top: 60px;
      }
    }

    //@include bp(tablet) {
    //  top: percentage(140 / $content-max-width);
    //}
    //@include bp(mobile-tablet) {
    //  top: percentage(140 / $content-max-width);
    //}
  }

  p {
    @include fontStyle2();
    @include remy(37);
    line-height: percentage(44/38);
    width: percentage(10 / 20);

    @include linkSecondaryColor();
    @include linkUnderLine();

    @include bp(mobile-tablet) {
      width: 100%;
      @include remy(21);
      line-height: 26px;
    }

  }

  .quote {
    position: relative;
    background-color: $brand-primary-transparent;
    color: $brand-secondary;

    // TODO: Move this
    .quote-bg {
      position: absolute;
      width: 100%;
      z-index: -1;
      height: 140px;
      background-color: $brand-primary;
      top: 0;
      left: 0;
    }

    .quote-inner {
      padding-top: percentage(150 / $content-max-width);
      padding-bottom: percentage(300 / $content-max-width);

      @include bp(mobile-tablet) {
        padding-bottom: 120px;
      }
    }
  }
}

.blog .new-parallax {
  height: 800px;

  @include bp(mobile-tablet) {
    height: 350px;
  }
}

.blog #category-footer-share {
  margin-top: -400px;
  margin-bottom: 60px;
}

.blog-no-results {
  margin-bottom: 120px;

  h1 {
    color: $brand-primary;
    @include remy(160);
    line-height: 1;
    margin-bottom: 54px;
  }

  p {
    color: $brand-primary;
    margin-bottom: 40px;
    @include remy(20);
    line-height: 28px;
    margin-bottom: 64px;
  }
}
