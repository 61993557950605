/* Contact */

body.contact {
  main {
    color: $brand-primary;

    @include linkPrimaryColor();

    .contact-list {
      color: $brand-secondary;
    }
  }
}

.contact #page-intro p {
  margin-bottom: 140px;

  @include bp(mobile-tablet) {
    margin-bottom: 80px;
  }
}

.contact-list {
  display: table;
  margin-bottom: 80px;

  @include bp(mobile-tablet) {
    margin-bottom: 10px;
  }

  .contact-list-inner {
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: percentage(10.4 / 22);
    margin-right: percentage(1 / 22);
    margin-bottom: percentage(1 / 22);

    &:nth-child(even) {
      margin-right: 0;
    }

    @include bp(mobile-tablet) {
      img {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        z-index: -1;
      }
    }

    .contact-list-item {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      padding: 35px;

      @include bp(mobile-tablet) {
        position: static;
        margin: 55px auto 38px;
        padding: 0;
        width: $content-mobile-inner-width;
      }

      .contact-list-item-wrapper {
        width: 100%;
        height: 100%;
        background-color: $brand-primary-transparent;
        padding: 40px;

        @include bp(mobile-tablet) {
          padding: 30px;

        }

        .contact-list-item-inner {
          position: relative;
          width: 100%;
          height: 100%;

          h2 {
            @include remy(28);
            margin: 0;

            @include bp(mobile-tablet) {
              @include fz_vw(28);
              line-height: percentage(62/56);
              margin: 0 0 18px;
            }
          }

          p {
            @include linkUnderLine();
            @include linkSecondaryColor();

            @include bp(mobile-tablet) {
              @include fz_vw(14);
            }
          }

          dl {
            @include linkUnderLine();
            @include linkSecondaryColor();
          }

          .top {
            @include bp(mobile-tablet) {
              margin-bottom: 57px;
            }

            p {
              margin-bottom: 10px;
              max-width: 66.66%;

              @include bp(mobile-tablet) {
                //margin-bottom: 30px;
                max-width: 100%;
              }
            }
          }

          .bottom {
            position: absolute;
            width: 100%;
            top: 100%;
            bottom: auto;
            transform: translate(0, -100%);
            margin-top: 9px;

            @include bp(mobile-tablet) {
              position: static;
              transform: none;
            }

            p {
              margin-bottom: 21px;
            }

            ul {
              width: 100%;
              display: table;

              li {
                vertical-align: bottom;

                &.full {
                  width: 100%;

                  dt {
                    @include bp(mobile-tablet) {
                      margin-bottom: 16px;
                    }
                  }
                }

                &.half {
                  display: inline-block;
                  width: 45%;

                  &:last-of-type {
                    margin-left: 10%;
                  }

                  @include bp(mobile-tablet) {
                    width: 100%;

                    &:last-of-type {
                      margin-left: 0;
                    }
                  }
                }

                dt {
                  margin-bottom: 9px;

                  @include bp(mobile-tablet) {
                    margin-bottom: 0;
                  }
                }

                dd {
                  @include bp(mobile-tablet) {
                    margin-bottom: 9px;
                  }

                  & + dd {
                    margin-top: 9px;
                  }
                }
              }
            }
          }

        }
      }
    }

    @include bp(mobile) {
      width: 100%;
      margin-right: 0;
    }
  }
}

.contact-join {
  width: 100%;

  p.mainText {
    width: percentage(7 / 20);
    @include fontStyle2();
    @include remy(26);
    line-height: 30px;

    @include bp(mobile-tablet) {
      width: 100%;
      margin-bottom: 50px;
    }
  }

  @include boxLinkWhite( percentage(2.5 / 20) );

  @include bp(mobile-tablet) {
    @include boxLinkWhite(100%);
  }
}
